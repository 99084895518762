import makeRequest, { RequestMethod } from "../../make.request";
import urls from "../../urls";

export default class UploadService {
    static async addQuestionToShift(examId: any, shiftId: any, payload: any) {
        return await makeRequest(urls.protected.questions.addQuestionToShift + "/" + examId + "/" + shiftId, RequestMethod.PUT, payload)
    }

    static async uploadToS3(formData: any) {
        return await makeRequest(urls.protected.exam.uploadToS3, RequestMethod.POST, formData)
    }
}