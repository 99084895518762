import IRouter from "../../../interfaces/IRouter";
import { EditLiveExamDropDownRouter } from "../../../Router";

interface IEditLiveExamIndex {
  children: IRouter[];
}

export default function EditLiveExamIndex(props: IEditLiveExamIndex) {
  return (
    <>
      <EditLiveExamDropDownRouter children={props.children} />
    </>
  );
}
