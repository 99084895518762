import { Badge, Table } from "flowbite-react";
import React from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { HiArchiveBoxXMark, HiCheckCircle, HiXCircle } from "react-icons/hi2";
import ExamService from "../../services/private/Exam/exam.service";

interface ILabDevicesTable {
  tableData: any;
  reload: any;
}

const LabDevicesTable = ({ tableData, reload }: ILabDevicesTable) => {
  return (
    <>
      <Table hoverable={true}>
        <Table.Head>
          <Table.HeadCell>S.No.</Table.HeadCell>
          <Table.HeadCell>Computer Id</Table.HeadCell>
          <Table.HeadCell>IP address</Table.HeadCell>
          <Table.HeadCell>MAC address</Table.HeadCell>
          <Table.HeadCell>Center Id</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {tableData && tableData.length > 0
            ? tableData.map((device: any, index: any) => {
                return (
                  <Table.Row
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    key={index}
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {index + 1}
                    </Table.Cell>
                    <Table.Cell>{device ? device.computerId : "--"}</Table.Cell>
                    <Table.Cell>{device ? device.ipAddress : "--"}</Table.Cell>
                    <Table.Cell>{device ? device.macAddress : "--"}</Table.Cell>
                    <Table.Cell>{device ? device.centerId : "--"}</Table.Cell>
                  </Table.Row>
                );
              })
            : "No device data available"}
        </Table.Body>
      </Table>
    </>
  );
};

export default LabDevicesTable;

// isActive
