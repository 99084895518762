import { Card } from 'flowbite-react'
import React from 'react'
import { VscDebugDisconnect } from "react-icons/vsc";



interface IStudentCard {
  type?: any,
  text: string,
  count: any
}


const StudentBlockedCard = ({ text, count, type }: IStudentCard) => {
  return (
    <div className="max-w-sm mx-5">
      {type === "blocked" && count > 0 ? (
        <Card
          style={{ width: "14rem" }}
          className={
            type === "blocked"
              ? "bg-red-500 bg-opacity-80 rounded-2xl rounded-2xl border-1 border-gray-100"
              : ""
          }
        >
          <div className='flex items-center'>
            <div>
              <VscDebugDisconnect className='text-white text-xl' />
            </div>
            <h5 className="text-xl font-semibold text-white tracking-wide text-left align-text-top uppercase font-medium ml-1">
              {text}
            </h5>
          </div>
          <div>
            <h5 className="text-xl font-semibold text-white tracking-wide text-left font-normal">
              {count}
            </h5>
          </div>
        </Card>
      ) : (
        <Card
          style={{ width: "14rem" }}
          className="outline-black rounded-2xl border-2 border-gray-100"
        >
          <div>
            <h5 className="text-xl font-semibold text-white tracking-wide text-left align-text-top uppercase font-medium">
              {text}
            </h5>
          </div>
          <div>
            <h5 className="text-xl font-semibold text-white tracking-wide text-left font-normal">
              {count}
            </h5>
          </div>
        </Card>
      )}
    </div>
  );
}

export default StudentBlockedCard

// "bg-green-600 opacity-90"