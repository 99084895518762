import React, { useEffect, useRef, useState } from 'react'
import ReservationService from '../../../../services/private/Exam/reservation.service';
import { Button, Card, Label, Table, Tabs, TextInput } from 'flowbite-react';
import { useParams } from 'react-router';
import ExamService from '../../../../services/private/Exam/exam.service';
import TabsIndex from '../../../../components/Tabs/Tabs.index';
import CenterStudentTable from '../../../../components/Tables/Center.student.table';
import toast from 'react-hot-toast';
import CenterDetailModal from '../../../../components/Modals/CenterDetail.modal';


export default function CenterStudents() {


    const { examId } = useParams();
    const [shifts, setShifts] = useState<any>();
    const [selectedShift, setSelectedShift] = useState<any>();
    const [defaultShift, setDefaultShift] = useState<any>();
    const [centerData, setCenterData] = useState<any>();
    const [allocationData, setAllocationData] = useState<any>();
    const [showCenter,setShowCenter]=useState<boolean>(false);

    const getAllShifts = async () => {
        await ExamService.getExamShifts(examId).then((res) => {
            if (res.status === 200) {
                setShifts(res.data.map((data: any) => {
                    return {
                        value: data.shiftid,
                        label: data.shiftname
                    }
                }))
            }
        })
    }

    const getCandidatesPerCenter = async () => {
        await ExamService.getCandidatesPerCenter(examId, selectedShift?.value).then((res) => {
            if (res.status === 200) {
                setCenterData(res?.data);
            }
        }).catch(err => {
            toast.error("Unable to fetch Student data")
        })
    }

    const labAllotment = async () => {
        await ExamService.labAllotment(examId, selectedShift.value).then((res) => {
            if (res.status === 200) {
                setAllocationData(res.data);
                toast.success("Allocation successfull");
            }
        }).catch(err => {
            toast.error(err)
        })
    }

    useEffect(()=>{
    setDefaultShift(shifts && shifts[0]);
    setSelectedShift(defaultShift)
    },[shifts])

    useEffect(() => {
        getAllShifts();
       
    }, [])

    useEffect(() => {
      if (selectedShift) {
        getCandidatesPerCenter();
      }
    }, [selectedShift]);

    return (<>
        <div className="my-10 mx-5">
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                Center Student Data
            </h5>
            <TabsIndex
                items={shifts}
                onChange={(tab: any,i:number) => { setSelectedShift(tab) }}
            />
            <div className='flex float-right mt-5 me-10'>
                <Button onClick={selectedShift && labAllotment}>
                    Allocate Lab to Students
                </Button>
            </div>
            <Card className='my-20'>
                <CenterStudentTable
                    tableData={centerData}
                    showCenter={showCenter}
                    setShowCenter={setShowCenter}
                />
            </Card>
        </div>
        <CenterDetailModal show={showCenter} setShow={setShowCenter}/>
    </>
    )


}