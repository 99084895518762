import React from 'react'
import { Modal } from 'flowbite-react';
import CenterDetailTable from '../Tables/CenterDetail.table';

interface ICenterDetailModal {
  show: any;
  setShow: any;
}

const CenterDetailModal = ({show,setShow}:ICenterDetailModal) => {
  return (
    <div>
      <Modal
        show={show}
        onClose={() => setShow(false)}
        id="defaultModal"
        aria-hidden="true"
        className="z-50 w-full p-4 overflow-auto h-full"
        size="lg"
      >
        <Modal.Header>Center Detail</Modal.Header>
        <Modal.Body>
         <CenterDetailTable tableData={''} showCenter={true} setShowCenter={setShow}/>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CenterDetailModal;
