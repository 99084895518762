import React from "react";
import Zone from "./Zone";

const ZoneIndex = () => {
  return (
    <div>
        <Zone/>
    </div>
  );
};

export default ZoneIndex;
