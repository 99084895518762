import { Button, Card } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import EditHooksModal from '../../../../components/Modals/EditHooks.modal'
import ExamService from '../../../../services/private/Exam/exam.service';

const Exmbnk = () => {
    const { examId } = useParams();

    const [exmbnkData, setExmbnkData] = useState<any>({});
    const [showBnkModal, setShowBnkModal] = useState<boolean>(false)
   

    const getHooksData = async () => {
        await ExamService.getHooksData(examId).then((res: any) => {
            if (res.status === 200) {
                console.log(res.data, "res");
                setExmbnkData({
                    EXMBNK: res.data.EXMBNK,
                    EXMBNK_KEY: res.data.EXMBNK_KEY
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    const onUpdate = async () => {

        await ExamService.updateExamUrls(examId, { EXMBNK : exmbnkData.EXMBNK, EXMBNK_KEY: exmbnkData.EXMBNK_KEY }).then((res) => {
            if (res.status === 200) {
                toast.success("Succesfully Updated");
                setShowBnkModal(false);
                getHooksData();
            }
        }).catch((err) => {
            toast.error("Failed to Update");
        });
    };

    useEffect(() => {
        getHooksData();
    }, []);

    return (
        <div className="ml-10 mt-10">
            <Card>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        EXMBNK
                    </h5>
                    <Button onClick={() => setShowBnkModal(true)}>
                        Edit
                    </Button>
                </div>
                <Card>
                    <div>
                        <p className="font-bold text-gray-700 dark:text-gray-400">
                            URL: {" "}{" "}{exmbnkData ? exmbnkData.EXMBNK : "--"}
                        </p>
                        <p className="font-bold text-gray-700 dark:text-gray-400">
                            KEY: {" "}{" "}{exmbnkData ? exmbnkData.EXMBNK_KEY : "--"}
                        </p>
                    </div>
                </Card>
            </Card>
            <EditHooksModal
                show={showBnkModal}
                onClose={() => setShowBnkModal(false)}
                stateData={exmbnkData}
                onChange={(new_state:any) => {setExmbnkData(new_state)}}
                onSubmission={onUpdate}
            />
        </div>
    )
}

export default Exmbnk