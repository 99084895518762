import { HiDocument } from "react-icons/hi2";
import IRouter from "../interfaces/IRouter";
import ExamClocks from "../views/Exam/Clocks/ExamClocks";

const clocksRouter: IRouter[] = [
  {
    path: "clocks",
    element: <ExamClocks/>,
    navbarShow: true,
    name: "Exam Clocks",
    icon: HiDocument,
  },
  
];

export default clocksRouter;