import IRouter from "../interfaces/IRouter";
import { HiBookOpen } from "react-icons/hi2";
import LastSyncLog from "../views/Monitoring/LastSyncLog/LastSyncLog.index";

const monitoringlastsynclogrouter: IRouter[] = [
  {
    path: "",
    element: <LastSyncLog />,
    navbarShow: true,
    name: "Last Sync Log",
    icon: HiBookOpen,
  },
];

export default monitoringlastsynclogrouter;
