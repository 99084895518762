import React, { useEffect, useState } from 'react'
import { Button, Label, Modal, TextInput } from 'flowbite-react'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import ExamService from '../../services/private/Exam/exam.service';

interface IEditClockModal {
  show: any;
  onClose: any;
  data: any;
  name: string;
  isRange?: any;
  isExamTime?: any;
  isDataSync?: any;
  isClicked: boolean;
  isExamStartTime?: any;
  isExamEndTime?: any;
  isCandidateStartTime?: any;
  inputName?: any;
  reload: any;
  inputExamStart: any;
  inputExamEnd: any
}


const EditClockModal = ({
  show,
  onClose,
  data,
  name,
  isRange,
  isDataSync,
  isClicked,
  isExamStartTime,
  isExamEndTime,
  inputName,
  inputExamStart,
  inputExamEnd,
  reload,
  isExamTime
}: IEditClockModal) => {


  const [clock_data, setClock_data] = useState<any>({});
  const [final_data, setFinal_data] = useState<any>({});
  const [finalStartExamData, setFinalStartExamData] = useState<any>({});
  const [isExamStart, setIsExamStart] = useState<boolean>(false);
  const [isExamEnd, setIsExamEnd] = useState<boolean>(false);
  const [finalEndExamData, setFinalEndExamData] = useState<any>({});
  const [examData, setExamData] = useState<any>();
  const { examId }: any = useParams();

  useEffect(() => {
    setClock_data({ ...data });
    setExamData({ ...data })
  }, [show, data]);

  const onChangeClockData = (e: any) => {
    const finalInputName = isRange || isExamTime ? e.target.name:"";
    setClock_data({ ...clock_data, [inputName]: e.target.value });
    setExamData({ ...examData, [finalInputName]: e.target.value });
  };
  const clockExamStartDate = (isExamStartTime && moment(isRange? examData?.shiftstartdateandtime
 :examData?.examstarttime || new Date()).toDate());
  const clockExamEndDate = (isExamEndTime && moment(isRange? examData?.shiftenddateandtime:examData?.examendtime || new Date()).toDate());
  const clockDate =
    (isDataSync && (clock_data?.synctime || 0)) ||
    (isClicked &&
      moment(
        (name === "Exam Start Time" &&
          (clock_data?.examstarttime)) ||
        (name === "Exam End Time" &&
          (clock_data?.examendtime)) ||
        (name === "Biometric Start Time" &&
          (clock_data?.biometricstarttime)) ||
        (name === "Candidate Data time" &&
          clock_data?.candidatedatetime) ||
        (name === "Candidate Start time" &&
          clock_data?.candidatestarttime) ||
        (name === "ExamSte Download Time" &&
          clock_data?.examstedownloadtime) ||
        (name === "Gate closing time" &&
          clock_data?.gateclosingtime) ||
        (name === "Live Exam start Time" &&
          clock_data?.liveexamstarttime) ||
        (name === "Login Time" && clock_data?.logintime) ||
        (name === "Minimum Submission Time" &&
          clock_data?.minimumsubmissiontime) ||
        (name === "QP delivery time" &&
          clock_data?.qpdeliverytime) ||
        (name === "User data delivery time" &&
          clock_data?.userdatadeliverytime) || new Date()).toDate()
    );



  useEffect(() => {
    setFinal_data({ [inputName]: clockDate });
    setFinalStartExamData({ [inputExamStart]: clockExamStartDate });
    setFinalEndExamData({ [inputExamEnd]: clockExamEndDate });
  }, [inputName, clock_data, examData, inputExamStart, inputExamEnd]);



  const onUpdateClock = async () => {
    if (inputName) {
      await ExamService.updateShiftTime(examId, clock_data.shiftid, final_data).then(
        (res) => {
          if (res.status === 200) {
            onClose();
            reload();
            toast.success("Updated Values");
          }
        });
    }

    if (isExamStart) {
      const res = await ExamService.updateShiftTime(
        examId,
        clock_data.shiftid,
        finalStartExamData
      )
      if (res.status === 200) {
        onClose();
        reload();
        toast.success("Updated Start time");
      }

    }

    if (isExamEnd) {
      await ExamService.updateShiftTime(
        examId,
        clock_data.shiftid,
        finalEndExamData
      ).then((res) => {
        if (res.status === 200) {
          onClose();
          reload();
          toast.success("Updated End Time");
        }
      });
    }

  };

  return (
    <>
      <Modal show={show} size="md" popup={show} onClose={onClose}>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              Edit {name}
            </h3>

            {isRange || isExamTime ? (
              <>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor={
                        isRange ? "shiftexamstartdateandtime" : "examstarttime"
                      }
                      value={isRange ? "Shift Start Time" : "Exam Start Time"}
                    />
                  </div>

                  <DatePicker
                    selected={(examData && clockExamStartDate)}
                    onChange={(data: any) => {
                      console.log(data, "data");
                      const e = {
                        target: {
                          name: isRange
                            ? "shiftstartdateandtime"
                            : "examstarttime",
                          value:  data,
                        },
                      };
                      onChangeClockData(e);
                      setIsExamStart(true);
                    }}
                    showTimeSelect
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    dateFormat={"MMM d, yyyy h:mm aa"}
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor={isRange ? "shiftenddateandtime" : "examendtime"}
                      value={isRange ? "Shift End Time" : "Exam End Time"}
                    />
                  </div>

                  <DatePicker
                    selected={examData && clockExamEndDate}
                    // selected={new Date()}
                    onChange={(data: any) => {
                      const e = {
                        target: {
                          name: isRange ? "shiftenddateandtime" : "examendtime",
                          value: data,
                        },
                      };
                      onChangeClockData(e);
                      setIsExamEnd(true);
                    }}
                    showTimeSelect
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    dateFormat={"MMM d, yyyy h:mm aa"}
                  />
                </div>
              </>
            ) : (
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor={isDataSync ? "time" : "datetime"}
                    value={isDataSync ? "Time(mins)" : "Date"}
                  />
                </div>
                {isDataSync ? (
                  <TextInput
                    id="time"
                    type="number"
                    onChange={(data: any) => {
                      const e = {
                        target: {
                          name: { inputName },
                          value: Number(data.target.value),
                        },
                      };
                      onChangeClockData(e);
                    }}
                    defaultValue={clock_data?.synctime}
                    value={Number(clockDate)}
                    required
                  />
                ) : (
                  <DatePicker
                    selected={clockDate}
                    onChange={(data: any) => {
                      const e = {
                        target: {
                          name: { inputName },
                          value: data,
                        },
                      };
                      onChangeClockData(e);
                    }}
                    showTimeSelect
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    dateFormat={"MMM d, yyyy h:mm aa"}
                  />
                )}
              </div>
            )}
            <div className="w-full">
              <Button onClick={onUpdateClock}>Update</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditClockModal