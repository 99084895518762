import { Table } from 'flowbite-react'
import React, { useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa';
import BufferIndex from '../../views/Exam/Buffer/Buffer.index';
import EditNormalizationModal from '../Modals/EditNormalization';

interface INormalizationShiftTable {
  tableData: any;
  randomData: any;
  reload:any;
}

const NormalizationShiftTable = ({
  tableData,
  randomData,
  reload
}: INormalizationShiftTable) => {
     const [index,setIndex]=useState<any>();
    const [showEditModal,setShowEditModal]=useState<boolean>();
    
  return (
    <>
      <div>
        <Table hoverable={true}>
          <Table.Head>
            <Table.HeadCell>Sr. No.</Table.HeadCell>
            <Table.HeadCell>Shift Id</Table.HeadCell>
            <Table.HeadCell>Shift Name</Table.HeadCell>
            <Table.HeadCell>Factor</Table.HeadCell>
            <Table.HeadCell>Action</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {tableData && tableData.length > 0
              ? tableData.map((data: any, index: number) => {
                  const random = randomData.find(
                    (number: any) => number == index
                  );
                  console.log(random, "sjfhasuhgfash");
                  return (
                    <Table.Row
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      key={index}
                    >
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {index + 1}
                      </Table.Cell>
                      <Table.Cell>{data ? data.shiftid : "--"}</Table.Cell>
                      <Table.Cell>{data ? data.shiftname : "--"}</Table.Cell>
                      <Table.Cell>{data?.normalization|| "--"}</Table.Cell>
                      <Table.Cell>
                        <span
                          className="font-medium text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
                          onClick={() => {
                            setIndex(index);
                            setShowEditModal(true);
                          }}
                        >
                          <FaPencilAlt/>
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              : "No buffer data found"}
          </Table.Body>
        </Table>
      </div>
      <EditNormalizationModal show={showEditModal} setShow={setShowEditModal} reload={reload} data={tableData} index={index} />
    </>
  );
};

export default NormalizationShiftTable