import makeRequest, { RequestMethod } from "../../make.request";
import urls from "../../urls";

export default class ExamService {
  static async getStudentRegistration() {
    return await makeRequest(
      urls.protected.students.getStudentsFromEXMREG,
      RequestMethod.GET
    );
  }

  static async getExamById(examId: any) {
    return await makeRequest(
      urls.protected.exam.getExamById + "/" + examId,
      RequestMethod.GET
    );
  }

  static async updateExam(examId: any, payload: any) {
    return await makeRequest(
      urls.protected.exam.updateExam + "/" + examId,
      RequestMethod.PUT,
      payload
    );
  }

  static async getExamShifts(examId: any) {
    return await makeRequest(
      urls.protected.exam.getExamShifts + "/" + examId,
      RequestMethod.GET
    );
  }

  static async getHooksData(examId: any) {
    return await makeRequest(
      urls.protected.exam.getHooksData + "/" + examId,
      RequestMethod.GET
    );
  }

  static async addShifts(examId: any, payload: any) {
    return await makeRequest(
      urls.protected.exam.addShifts + "/" + examId,
      RequestMethod.PUT,
      {
        name: payload.name,
        shiftStartDateAndTime: payload.shiftStartDateAndTime,
        shiftEndDateAndTime: payload.shiftEndDateAndTime,
      }
    );
  }

  static async deleteShift(examId: any, shiftId: any) {
    return await makeRequest(
      urls.protected.exam.deleteShift,
      RequestMethod.POST,
      {
        examId: examId,
        shiftId: shiftId,
      }
    );
  }

  static async createPassword(examId: any, shiftId: any) {
    return await makeRequest(
      urls.protected.exam.createPassword + "/" + examId + `/` + shiftId,
      RequestMethod.PUT,
      {
        examId: examId,
        shiftId: shiftId,
      }
    );
  }
  static async toggleExamShiftLiveStatus(
    examId: any,
    shiftId: any,
    status: boolean
  ) {
    return await makeRequest(
      urls.protected.exam.toggleExamShiftLiveStatus +
      "/" +
      examId +
      "/" +
      shiftId,
      RequestMethod.PUT,
      {
        status: status,
      }
    );
  }
  static async getTimeBuffer(examId: any) {
    return await makeRequest(
      urls.protected.exam.getTimeBuffer + "/" + examId,
      RequestMethod.GET
    );
  }

  static async addTimeBuffer(examId: any, payload: any) {
    return await makeRequest(
      urls.protected.exam.addTimeBuffer + "/" + examId,
      RequestMethod.PUT,
      payload
    );
  }

  static async generateClientAccount(examId: any) {
    return await makeRequest(
      urls.protected.exam.generateClientAccount + "/" + examId,
      RequestMethod.PUT
    );
  }

  static async getExamSections(examId: any) {
    return await makeRequest(
      urls.protected.exam.getExamSections + "/" + examId,
      RequestMethod.GET
    );
  }

  static async addSection(examId: any, payload: any) {
    return await makeRequest(
      urls.protected.exam.addSection + "/" + examId,
      RequestMethod.POST,
      { sectionData: payload }
    );
  }

  static async updateTimeBuffer(examId: any, payload: any) {
    return await makeRequest(
      urls.protected.exam.updateTimeBuffer + "/" + examId,
      RequestMethod.PUT,
      payload
    );
  }

  static async checkIfQuestionPaperExistForExam(examId: string) {
    return await makeRequest(
      urls.protected.exam.checkIfQuestionPaperExistForExam + "/" + examId,
      RequestMethod.GET
    );
  }

  static async getAllCenterByExamId(examId: any) {
    return await makeRequest(
      urls.protected.exam.getAllCenterByExamId + "/" + examId,
      RequestMethod.GET
    );
  }

  static async getAllStudentData() {
    return await makeRequest(
      urls.protected.exam.getAllStudents,
      RequestMethod.GET
    );
  }

  static async updateExamUrls(examId: any, payload: any) {
    return await makeRequest(
      urls.protected.exam.updateHooksData + "/" + examId,
      RequestMethod.PUT,
      payload
    );
  }

  static async updateShift(examId: string, shiftId: string, payload: any) {
    return await makeRequest(
      urls.protected.exam.updateShift + "/" + examId + "/" + shiftId,
      RequestMethod.PUT,
      payload
    );
  }

  static async updateShiftTime(
    examId: string,
    shiftId: string,
    payload: {
      shiftname: string;
      shiftstartdateandtime: any;
      shiftenddateandtime: any;
    }
  ) {
    return await makeRequest(
      urls.protected.exam.updateShiftTime + "/" + examId + "/" + shiftId,
      RequestMethod.PUT,
      payload
    );
  }

  static async getCandidatesPerCenter(examId: any, shiftId: any) {
    return await makeRequest(
      urls.protected.exam.candidatesPerCenter + "/" + examId + "/" + shiftId,
      RequestMethod.GET
    );
  }

  static async getExmsteServerAndBioDevices(examId: any) {
    return await makeRequest(
      urls.protected.exam.getExmsteServerAndBioDevices + "/" + examId,
      RequestMethod.GET
    );
  }

  static async createExamSTEUsers(examId: any, shiftId: any, payload: any) {
    return await makeRequest(
      urls.protected.Exmste.createExamSTEUsers + "/" + examId + "/" + shiftId,
      RequestMethod.POST,
      { data: payload }
    );
  }

  static async uploadUserDataZip(examId: any, shiftId: any, payload: any) {
    return await makeRequest(
      urls.protected.students.uploadUserDataZip + "/" + examId + "/" + shiftId,
      RequestMethod.POST,
      payload
    );
  }

  static async uploadAnswerKey(examId: any, shiftId: any, payload: any) {
    return await makeRequest(
      urls.protected.students.uploadAnswerKey + "/" + examId + "/" + shiftId,
      RequestMethod.POST,
      payload
    );
  }

  static async editBioDevicesAndExmsteCount(examId: any, payload: any) {
    return await makeRequest(
      urls.protected.exam.editBioDevicesAndExmsteCount + "/" + examId,
      RequestMethod.PUT,
      payload
    );
  }

  static async getCenters(examId: any) {
    return await makeRequest(
      urls.protected.exam.getCenters + "/" + examId,
      RequestMethod.GET
    );
  }

  static async getLiveExamCenters(examId: any) {
    return await makeRequest(
      urls.protected.exam.getLiveExamCenters + "/" + examId,
      RequestMethod.GET
    );
  }

  static async checkExamSteUser(examId: any, shiftId: any) {
    return await makeRequest(
      urls.protected.Exmste.checkExamSteUser + "/" + examId + "/" + shiftId,
      RequestMethod.GET
    );
  }

  static async getAllZones(examId: any) {
    return await makeRequest(
      urls.protected.zone.getAllZonesByExamId + "/" + examId,
      RequestMethod.GET
    );
  }

  static async createZone(examId: any, shiftId: any, payload: any) {
    console.log(payload, "payload");
    return await makeRequest(
      urls.protected.zone.createZones + "/" + examId + "/" + shiftId,
      RequestMethod.POST,
      {
        username: payload.zoneData.username,
        centers: payload.selectedNames,
        zoneName: payload.zoneData.zoneName,
      }
    );
  }

  static async deleteZone(zoneId: any) {
    return await makeRequest(
      urls.protected.zone.deleteZone + "/" + zoneId,
      RequestMethod.DELETE
    );
  }

  static async labAllotment(examId: any, shiftId: any) {
    return await makeRequest(
      urls.protected.Exmcen.labAllotment + "/" + examId + "/" + shiftId,
      RequestMethod.GET
    );
  }

  static async deleteSection(sectionId: any) {
    return await makeRequest(
      urls.protected.section.delete + "/" + sectionId,
      RequestMethod.DELETE
    );
  }

  static async initialExmsteUser(examId: string, shiftId: string) {
    return await makeRequest(
      urls.protected.Exmste.initialExmsteUser + "/" + examId + "/" + shiftId,
      RequestMethod.GET
    );
  }

  static async removeExamSteUser(examId: string, shiftId: string) {
    return await makeRequest(
      urls.protected.Exmste.removeExamSteUser + "/" + examId + "/" + shiftId,
      RequestMethod.DELETE
    );
  }

  static async generatePassword(uuid: any) {
    return await makeRequest(urls.protected.zone.generatePassword + "/" + uuid, "put")
  }

  static async toggleMockExam(uuid: any) {
    return await makeRequest(urls.protected.exam.toggleMockExam + "/" + uuid, "put")
  }


  static async uploadUsersCSV(examId: any, shiftId: any, payload: any) {
    return await makeRequest(
      urls.protected.students.uploadUserCsvData + "/" + examId + "/" + shiftId,
      RequestMethod.POST,
      payload
    );
  }

}
