import { Card } from 'flowbite-react';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import BufferModal from '../../../components/Modals/buffer.modal';
import BufferTable from '../../../components/Tables/Buffer.table';
import ExamService from '../../../services/private/Exam/exam.service'

const ExamBuffer = () => {
    const { examId } = useParams();

    const [bufferData, setBufferData] = useState<any>();
    const [showBufferMOdal, setShowBufferModal] = useState<boolean>(false);
    const [bufferInputValue, setBufferInputValue] = useState<any>();
    const [updateBufferInput, setUpdateBufferInput] = useState<any>();

    const handleChangeValue = (e: any) => {
        setBufferInputValue({ ...bufferInputValue, [e.target.name]: e.target.value })
    }


    const getBufferTime = async () => {
        await ExamService.getTimeBuffer(examId).then((res: any) => {
            if (res.status === 200) {
                setBufferData(res.data);
                console.log(res.data)
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const handleBufferSubmission = async () => {
        await ExamService.addTimeBuffer(examId, bufferInputValue).then((res: any) => {
            if (res.status === 200) {
                toast.success("Buffer created");
                setShowBufferModal(false);
                getBufferTime();
            }
        }).catch((err) => {
            toast.error("Unable to create buffer")
        })
    }

    useEffect(() => {
        getBufferTime();
    }, [])

    return (
        <>
            <div className="ml-10 mt-10">
                <Card>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            Time Buffers
                        </h5>

                        <span className="font-medium text-blue-600 hover:underline dark:text-blue-500" onClick={() => setShowBufferModal(true)}>
                            Add Buffer
                        </span>
                    </div>
                    <BufferTable tableData={bufferData} refresh={getBufferTime}/>
                </Card>
            </div>
            <BufferModal
                show={showBufferMOdal}
                onClose={() => { setShowBufferModal(false) }}
                onChange={handleChangeValue}
                handleSubmission={handleBufferSubmission}
            />
        </>
    )
}

export default ExamBuffer