import { Button, Modal } from 'flowbite-react'
import React from 'react'
import { AiOutlineSetting } from 'react-icons/ai'
import { HiOutlineExclamationCircle } from 'react-icons/hi2'
import { MdAdminPanelSettings } from 'react-icons/md'

interface IPasswordModal {
    show: any,
    onClose: any,

}

const PasswordModal = ({ show, onClose }: IPasswordModal) => {
    console.log(show, "password")
    return (
        <Modal
            show={show ? true : false}
            size="lg"
            popup={true}
            onClose={onClose}
        >
            <Modal.Header />
            <Modal.Body>
                <div className="text-center">
                    <MdAdminPanelSettings className="mx-auto mb-4 h-14 w-14 text-green-400 dark:text-gray-200" />
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        Generated Password
                    </h3>
                    <div style={{ marginLeft: "6rem" }}>
                        <div className='flex'>
                            <h4 className="text-lg font-medium text-dark-500 dark:text-gray-400">Email:-</h4>
                            <span className="text-lg font-medium text-gray-500 dark:text-gray-400">{show?.user}</span>
                        </div>
                        <div className='flex mx-10'>
                            <h4 className="text-lg font-medium text-dark-500 dark:text-gray-400">Password:-</h4>
                            <span className="text-lg font-medium text-gray-500 dark:text-gray-400">{show?.password}</span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PasswordModal