import React, { useEffect, useState } from 'react'
import { Button, Card, Label, Table, TextInput } from 'flowbite-react';
import ExamService from '../../../services/private/Exam/exam.service';
import Select from "react-select";
import { useParams } from 'react-router';
import toast from 'react-hot-toast';
import ZonesTable from '../../../components/Tables/zones.table';

const Zone = () => {
  const { examId } = useParams();

  const [centers, setCenters] = useState<any>();
  const [selectedNames, setSelectedNames] = useState<any>([]);
  const [shifts, setShifts] = useState<any>();
  const [zoneData, setZoneData] = useState<any>();
  const [data, setData] = useState<any>();
  const [shiftId, setShiftId] = useState<any>();


  const handleChange = (event: any) => {
    setSelectedNames(event);
  };


  const handleValueChange = (e: any) => {
    setZoneData({ ...zoneData, [e.target.name]: e.target.value });
  }

  const handleShiftValue = (e: any, name: any) => {
    setShiftId({ ...shiftId, [name]: e.value });
  }


  const getAllZones = async () => {
    await ExamService.getAllZones(examId).then((res: any) => {
      if (res.status === 200) {
        setData(res.data);
      }
    })
  }

  const getAllShifts = async () => {
    await ExamService.getExamShifts(examId).then((res) => {
      if (res.status === 200) {
        setShifts(res.data.map((data: any) => {
          return {
            value: data.shiftid,
            label: data.shiftname
          }
        }))
      }
    })
  }



  const fetchCenterData = () => {
    ExamService.getLiveExamCenters(examId)
      .then((res) => {
        if (res.status === 200) {
          setCenters(
            res?.data?.map((data: any, index: any) => {
              return {
                value: data,
                label: data,
              };
            })
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const handleZoneSubmission = async () => {
    await ExamService.createZone(examId, shiftId.shiftId, { zoneData, selectedNames: selectedNames.map((data: any) => { return data.value }) }).then((res: any) => {
      if (res.status === 200) {
        toast.success("Zones create successfully.");
        getAllZones();
      }
    }).catch(err => {
      toast.error("Unable to create zone");
    })
  }



  useEffect(() => {
    getAllZones();
    getAllShifts();
  }, [])

  useEffect(() => {
    fetchCenterData();
  }, [examId]);




  return (
    <>
      <div className="my-10 mx-5 w-full">
        <Card>
          <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            Create New Zone
          </h5>
          <div className='mt-1'>
            <Label className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Select Shift
            </Label>
            <Select
              onChange={(e: any) => { handleShiftValue(e, "shiftId") }}
              options={shifts}
              isClearable={true}
              hideSelectedOptions={true}
              placeholder="Select Shift"
              className=''
            />
          </div>
          <div>
            <Label className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Username
            </Label>
            <TextInput
              type="text"
              name="username"
              onChange={handleValueChange}
              required={true}
            />
          </div>
          <div>
            <Label className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Zone name
            </Label>
            <TextInput
              type="text"
              name="zoneName"
              onChange={handleValueChange}
              required={true}
            />
          </div>
          <div className='mt-1'>
            <Label className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Select Centers
            </Label>
            <Select
              isMulti
              onChange={handleChange}
              options={centers}
              isClearable={true}
              hideSelectedOptions={true}
              placeholder="Select the Centers..."
              className=''
            />
            <div className="flex flex-col items-end mt-3">
              <Button size="sm" onClick={handleZoneSubmission}>Create</Button>
            </div>
          </div>
        </Card>

      </div>
      <div className="my-10 mx-5 w-full">
        <Card>
          <ZonesTable tableData={data} reload={getAllZones} />
        </Card>
      </div>
    </>
  );
}

export default Zone;
