import React, { useEffect, useState } from "react";
import { Button, Card, Spinner } from "flowbite-react";
import { useParams } from "react-router";
import ExamService from "../../../services/private/Exam/exam.service";
import toast from "react-hot-toast";
import CreateShiftModal from "../../../components/Modals/CreateShifts";
import ShiftTable from "../../../components/Tables/ShiftTable";

const Shift = () => {
  const { examId } = useParams();

  const [examData, setExamData] = useState<any>([]);

  const [newShiftData, setNewShiftData] = useState<any>({});

  const [show, setShow] = useState(false);



  const getExamData = async () => {
    await ExamService.getExamShifts(examId)
      .then((res: any) => {
        if (res.status === 200) {
          setExamData(res?.data);

        }
      })
      .catch((err) => {
        toast.error("Unable to get shifts!!");
      });

  };


  function onNewShiftDataChange(value: any, name: any) {
    setNewShiftData({ ...newShiftData, [name]: value });
  }

  async function addNewShift() {
    await ExamService.addShifts(examId, {
      ...newShiftData,
    })
      .then(() => {
        toast.success("Added New Shift");
        setNewShiftData({});
        getExamData();
        setShow(false);
      })
      .catch((err) => {
        toast.error("Failed to Add Shift");
      });
  }

  useEffect(() => {
    getExamData();
  }, [show]);


  return (
    <>
      <div className="ml-10 mt-10">
        <Card>
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Exam Shifts
              </h5>

              <Button onClick={() => setShow(true)}>
                Add Shift
              </Button>
            </div>
            <ShiftTable
              tableData={examData}
              reload={getExamData}
            />{" "}
          </>
        </Card>
      </div>

      <CreateShiftModal
        show={show}
        setShow={setShow}
        onNewShiftDataChange={onNewShiftDataChange}
        addNewShift={addNewShift}
        newShiftData={newShiftData}
      />
    </>
  )
}

export default Shift;
