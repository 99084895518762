import { Modal } from 'flowbite-react';
import React from 'react'
import LabDetailTable from '../Tables/LabDetail.table';

interface ICenterDetailModal {
  showLabDetail: any;
  setShowLabDetail: any;
}
const LabDetailModal = ({showLabDetail,setShowLabDetail}:ICenterDetailModal) => {
  return (
    <div>
      <Modal
        show={showLabDetail}
        onClose={() => setShowLabDetail(false)}
        id="defaultModal"
        aria-hidden="true"
        className="z-50 w-full p-4 overflow-auto h-full"
        size="lg"
      >
        <Modal.Header>Lab Detail</Modal.Header>
        <Modal.Body>
          <LabDetailTable
            tableData={""}
            showLab={true}
            setShowLab={setShowLabDetail}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LabDetailModal
