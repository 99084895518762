import makeRequest, { RequestMethod } from "../../make.request";
import urls from "../../urls";

export default class AdminService {
  static async getAllExams() {
    return await makeRequest(
      urls.protected.admin.exams.allExams,
      RequestMethod.GET
    );
  }
  static async getAllLiveExams() {
    return await makeRequest(
      urls.protected.admin.exams.allLiveExam,
      RequestMethod.GET
    );
  }

  static async updatelLiveExams(examId: any, payload: any) {
    return await makeRequest(
      urls.protected.admin.exams.updateLiveExam + "/" + examId,
      RequestMethod.PUT,
      payload
    );
  }

  static async createExam(payload: any) {
    return await makeRequest(
      urls.protected.admin.exams.createExam,
      RequestMethod.POST,
      payload
    );
  }
}
