import IRouter from "../../../interfaces/IRouter"
import { EditExamDropDownRouter } from '../../../Router';

interface IEditExamIndex {
    children: IRouter[]
}

export default function EditExamIndex(props: IEditExamIndex) {

  
    return (
        <>
            <EditExamDropDownRouter children={props.children}/>
        </>
    )
}