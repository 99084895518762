import { Badge, Table } from "flowbite-react";
import React from "react";
import toast from "react-hot-toast";
import { HiArchiveBoxXMark, HiCheckCircle, HiXCircle } from "react-icons/hi2";
import ExamService from "../../services/private/Exam/exam.service";

interface IExtraTimingsTable {
  tableData: any;
  reload: any;
}

const ExtraTimingsTable = ({ tableData, reload }: IExtraTimingsTable) => {
  return (
    <>
      <Table hoverable={true}>
        <Table.Head>
          <Table.HeadCell>S.No.</Table.HeadCell>
          <Table.HeadCell>Given To</Table.HeadCell>
          <Table.HeadCell>Given By</Table.HeadCell>
          <Table.HeadCell>Alloted</Table.HeadCell>
          <Table.HeadCell>Time Alloted</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {tableData && tableData.length > 0
            ? tableData.map((extra: any, index: any) => {
                return (
                  <Table.Row
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    key={index}
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {index + 1}
                    </Table.Cell>
                    <Table.Cell>{extra ? extra.givenTo : "--"}</Table.Cell>
                    <Table.Cell>{extra ? extra.givenBy : "--"}</Table.Cell>
                    <Table.Cell>
                      {extra ? (
                        <Badge color={extra.alloted ? "success" : "failure"}>
                          {extra.alloted ? "true" : "false"}
                        </Badge>
                      ) : (
                        "--"
                      )}
                    </Table.Cell>
                    <Table.Cell>{extra ? extra.time : "--"}</Table.Cell>
                  </Table.Row>
                );
              })
            : "No extra time data available"}
        </Table.Body>
      </Table>
    </>
  );
};

export default ExtraTimingsTable;

// isActive
