import { Card } from "flowbite-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router";
import TabsIndex from "../../../../components/Tabs/Tabs.index";
import ExamService from "../../../../services/private/Exam/exam.service";

export default function ToggleIndex() {
  const { examId } = useParams();
  const [shifts, setShifts] = useState<any>();
  const [selectedShift, setSelectedShift] = useState<any>();
 const [checkedState, setCheckedState] = useState<boolean[]>([]);
 const [shiftIndex,setShiftIndex]=useState<number>(0);
 const [defaultShift, setDefaultShift] = useState<any>();

 console.log(selectedShift,checkedState,shifts,'shift')
console.log(checkedState,'state')

  const getAllShifts = async () => {
    await ExamService.getExamShifts(examId)
      .then((res) => {
        if (res.status === 200) {
          setShifts(
            res?.data?.map((data: any) => {
              return {
                value: data?.shiftid,
                label: data?.shiftname,
              };
            })
          );
           setCheckedState(
             res?.data?.map((data: any) => {
               return data?.is_live;
             })
           );
        }
      })
      .catch((err) => {
        toast.error("Unable to get shifts!!");
      });
  };

  console.log(shiftIndex,'index')

  const toggleExamShiftLiveStatus = async () => {
    if (shifts && selectedShift?.value &&  checkedState) {
      ExamService.toggleExamShiftLiveStatus(
        examId,
        selectedShift?.value,
        checkedState[shiftIndex]
      )
        .then((res) => {
          if (res.status === 200) {
            toast.success("Updated Succesfully");
          }
        })
        .catch((err) => {
          toast.error("Unable to toggle status!!");
        });
    }
  };


  useEffect(() => {
    getAllShifts();
  
  }, []);

  useEffect(()=>{
    setDefaultShift(shifts && shifts[0]);
    setSelectedShift(defaultShift)
  },[shifts])
  
  useEffect(() => {
    toggleExamShiftLiveStatus();
  }, [checkedState]);


  const handleOnChange = () => {
    const updatedCheckedState = checkedState?.map((item, index) =>
     {
        return index === shiftIndex ? !item : item;}
    );

    setCheckedState(updatedCheckedState);

  };

  return (
    <>
      <div className="my-10 mx-5 w-full">
        <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
          Live Status
        </h5>
        <TabsIndex
          items={shifts}
          onChange={(tab: any,i:number) => {
            setShiftIndex(i);
            setSelectedShift(tab);
          }}
        />
        <Card className="my-10 mx-5 w-full">
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                Change Status of the Shift {selectedShift==undefined ?defaultShift?.label :selectedShift?.label}
              </h5>
              <label className="inline-flex relative items-center mr-5 cursor-pointer mt-7">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={
                     checkedState[shiftIndex] || false
                  }
                  onChange={() => {
                    handleOnChange()}}
                  disabled={defaultShift?.value ? false:true}
                />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </>
        </Card>
      </div>
    </>
  );
}
