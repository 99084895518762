import { Button } from 'flowbite-react';
import React, { useState } from 'react'
import { Route, Routes } from 'react-router';
import IRouter from '../../../interfaces/IRouter';
import { EditExamDropDownRouter } from '../../../Router';
import Upload from './Student.upload'
import ViewData from './Flow/ViewData';

interface IUploadIndex {
    children: IRouter[]
}

const UploadIndex = (props: IUploadIndex) => {

    const [steps, setSteps] = useState<number>(0)

    const conditionalComponent = () => {
        switch (steps) {
            case 0:
                return <Upload />;
            case 1:
                return <ViewData />;
            default:
                return <Upload />;
        }
    };

    const stepHandler = () => {
        setSteps(steps + 1)
    }



    return (
        <>
        <EditExamDropDownRouter children={props.children}/>
        </>
    )
}

export default UploadIndex