import { HiOutlineBars3CenterLeft } from "react-icons/hi2";
import IRouter from "../interfaces/IRouter";
import ExtraTimings from "../views/Monitoring/ExtraTimings/ExtraTimings.index";

const monitoringextratimerouter: IRouter[] = [
  {
    path: "candidates",
    element: <ExtraTimings />,
    navbarShow: true,
    name: "Candidates",
    icon: HiOutlineBars3CenterLeft,
  },
];

export default monitoringextratimerouter;
