import { Card, Dropdown, Table } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { HiCheckCircle, HiXCircle } from 'react-icons/hi2';
import { useParams } from 'react-router';
import CreateSectionModal from '../../../components/Modals/CreateSection.modal';
import ExamService from '../../../services/private/Exam/exam.service'

const Section = () => {
    const { examId }: any = useParams();

    const [sectionsData, setSectionsData] = useState<any>();
    const [showSectionModal, setShowSectionModal] = useState<boolean>(false);
    const [statusData, setStatusData] = useState<any>();


    const getExamSections = async () => {
        await ExamService.getExamSections(examId).then((res: any) => {
            if (res.status === 200) {
                setSectionsData(res.data);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const deleteSection = async (sectionId: any) => {
        await ExamService.deleteSection(sectionId).then((res: any) => {
            if (res.status === 200) {
                toast.success("Deleted Section");
                getExamSections();
            }
        }).catch(err => {
            toast.error("Error")
        })
    }

    const checkIfQuestionPaperExistForExam = async () => {
        await ExamService.checkIfQuestionPaperExistForExam(examId).then((res: any) => {
            if (res.status === 200) {
                console.log(res.data, "status");
                setStatusData(res.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }


    useEffect(() => {
        getExamSections();
        checkIfQuestionPaperExistForExam();
    }, [showSectionModal])

    return (
        <>
            <div className="my-10 mx-5">
                <Card>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            Exam Section
                        </h5>

                        <span className="font-medium text-blue-600 hover:underline dark:text-blue-500" onClick={() => { setShowSectionModal(true) }}>
                            Add Sections
                        </span>
                    </div>
                    <div>
                        <Table hoverable={true}>
                            <Table.Head>
                                <Table.HeadCell>
                                    Section Name
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    ShiftId
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    Section Id
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    Question Paper Id
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    Question Status
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    <span className="sr-only">
                                        Edit
                                    </span>
                                </Table.HeadCell>
                            </Table.Head>
                            <Table.Body>
                                {sectionsData && sectionsData.length > 0 ? sectionsData.map((sections: any) => {
                                    const status = statusData && statusData.find((status: any) => status.sectionId == sections.uuid)
                                    return (
                                        <Table.Row key={sections.id}>
                                            <Table.Cell>
                                                {sections.sectionName}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {sections.shiftId}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {sections.uuid}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {sections.questionPaperId}
                                            </Table.Cell>

                                            <Table.Cell>
                                                {status && status.status === true ? <div className='flex justify-start'>
                                                    <HiCheckCircle className="text-green-600 text-lg" />
                                                    Available
                                                </div> : <div className='flex justify-start'>
                                                    <HiXCircle className="text-red-600 text-lg" /> Not Available
                                                </div>}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown
                                                    label="Actions"
                                                    size="sm"
                                                    color="light"
                                                >
                                                    <Dropdown.Item onClick={() => deleteSection(sections.uuid)}>
                                                        Delete
                                                    </Dropdown.Item>
                                                </Dropdown>
                                            </Table.Cell>

                                        </Table.Row>
                                    )
                                }) : <div className="text-center">No Data Found</div>}
                            </Table.Body>
                        </Table>


                    </div>
                </Card>
            </div>
            <CreateSectionModal
                show={showSectionModal}
                onClose={() => setShowSectionModal(false)}
            />
        </>
    )
}

export default Section