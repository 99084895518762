import { Card } from 'flowbite-react'
import React from 'react'
import { RiWifiOffLine } from "react-icons/ri";
import { GiAlarmClock } from "react-icons/gi";



interface IStatsCard {
    color?: string,
    text: string,
    count: string,
    updatedAt?: any,
    icon?: any
}

const StatsCard = ({ color, text, count, updatedAt, icon }: IStatsCard) => {
    return (
        <div className="max-w-sm mx-5">
            {/* <Card style={{ width: "20rem" }} className="outline-black rounded-2xl border-dotted border-2 border-red-500"> */}
            <Card style={{ width: "20rem" }} className={`outline-black rounded-2xl border-dotted border-2 ${color} mb-5`}>
                <div className='flex items-center'>
                    {/* <RiWifiOffLine className="text-xl" /> */}
                    {icon}
                    <h5 className="text-xl tracking-wide text-left align-text-top uppercase font-medium font-semibold ml-2">
                        {text}
                    </h5>
                </div>
                <div className='flex justify-between items-center'>
                    <h5 className="text-xl text-gray-500 tracking-wide text-left font-normal font-semibold">
                        {count}
                    </h5>
                    <div className='flex items-center'>
                        <span className='text-sm font-semibol italic ml-1'>
                            Last updated_at:
                        </span>
                        {/* <GiAlarmClock /> */}
                        <span className='text-sm font-semibol italic ml-1'>
                            12/12/2022
                        </span>
                    </div>
                </div>
            </Card>
        </div >
    )
}

export default StatsCard