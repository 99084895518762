import { Badge, Table } from "flowbite-react";
import React from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { HiArchiveBoxXMark, HiCheckCircle, HiXCircle } from "react-icons/hi2";
import ExamService from "../../services/private/Exam/exam.service";

interface ICandidateTimingsTable {
  tableData: any;
  reload: any;
}

const CandidateTimingsTable = ({
  tableData,
  reload,
}: ICandidateTimingsTable) => {
  return (
    <>
      <Table hoverable={true}>
        <Table.Head>
          <Table.HeadCell>S.No.</Table.HeadCell>
          <Table.HeadCell>Student Id</Table.HeadCell>
          <Table.HeadCell>Logged In At</Table.HeadCell>
          <Table.HeadCell>Instructions Read At</Table.HeadCell>
          <Table.HeadCell>Exam Start Time</Table.HeadCell>
          <Table.HeadCell>Center Id</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {tableData && tableData.length > 0
            ? tableData.map((timing: any, index: any) => {
                return (
                  <Table.Row
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    key={index}
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {index + 1}
                    </Table.Cell>
                    <Table.Cell>{timing ? timing.studentId : "--"}</Table.Cell>
                    <Table.Cell>
                      {timing.loggedInAt
                        ? moment(timing.loggedInAt).format(
                            "MMM DD YYYY hh:mm a"
                          )
                        : "--"}
                    </Table.Cell>
                    <Table.Cell>
                      {timing.instructionReadAt
                        ? moment(timing.instructionReadAt).format(
                            "MMM DD YYYY hh:mm a"
                          )
                        : "--"}
                    </Table.Cell>
                    <Table.Cell>
                      {timing.examStartedAt
                        ? moment(timing.examStartedAt).format(
                            "MMM DD YYYY hh:mm a"
                          )
                        : "--"}
                    </Table.Cell>
                    <Table.Cell>{timing ? timing.centerId : "--"}</Table.Cell>
                  </Table.Row>
                );
              })
            : "No timing data available"}
        </Table.Body>
      </Table>
    </>
  );
};

export default CandidateTimingsTable;

// isActive
