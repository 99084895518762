import { Button, Card, Checkbox } from 'flowbite-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { FaFontAwesome } from 'react-icons/fa'
import { HiPencil } from 'react-icons/hi2'
import { useParams } from 'react-router'
import ExamService from '../../../services/private/Exam/exam.service'
import UploadService from '../../../services/private/Upload/upload.service'
import InstructionUpload from './InstructionUpload'

const ExamDetail = () => {
  const { examId } = useParams();

  const [examData, setExamData] = useState<any>();
  const [editable, setEditable] = useState<boolean>(false)


  const handleCheckedValue = (e: any) => {
    setExamData({ ...examData, [e.target.name]: e.target.checked })
  }


  const getExamById = async () => {
    await ExamService.getExamById(examId).then((res) => {
      if (res.status === 200) {
        let exam = res.data;
        delete exam['shiftsData']
        delete exam['uuid']
        delete exam['id']
        delete exam['isCompleted']
        delete exam['isLocked']
        setExamData(exam);
      }
    }).catch(err => {
      toast.error("Unable to fetch exam data")
    })
  }

  const handleUploadedFile = async (files: any) => {
    let formData = new FormData();
    formData.append("file", files.target.files[0]);
    toast.promise(UploadService.uploadToS3(formData).then((res) => {
      if (res.status === 200) {
        setExamData({ ...examData, instructions: res.data });
      }
    }), {
      loading: 'Loading...',
      success: 'Upload Completed',
      error: 'Unable to upload file',
    });
  }

  const updateExam = async () => {
    await ExamService.updateExam(examId, examData).then((res) => {
      if (res.status === 200) {
        toast.success("Exam updated successfully.");
        setEditable(false)
        getExamById();
      }
    }).catch(err => {
      toast.error("Unable to updated exam")
    })
  }

  const handleMockExamStatus = async () => {
    await ExamService.toggleMockExam(examId).then((res) => {
      if (res.status === 200) {
        toast.success("Mock Exam updated successfully.");
        setEditable(false)
        getExamById();
      }
    }).catch(err => {
      toast.error("Unable to change status of mock exam")
    })
  }



  useEffect(() => {
    getExamById();
  }, [])


  return (
    <>
      <div className="mx-5 my-10">
        <Card className="w-full px-10">
          <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Exam Details
          </h5>
          <div className="flex justify-between items-center">
            <div className="pr-5 border-r-2">
              <div>
                <span>Exam name :-</span>
                <span className="pl-3 font-bold">
                  {examData ? examData.examName : "--"}
                </span>
              </div>
              <div>
                <span>Exam Date :-</span>
                <span className="pl-3 font-bold">
                  {examData &&
                    moment(examData.examDate).format("DD MMM YYYY hh:mm")}
                </span>
              </div>
              <div>
                <span>Active_from :-</span>
                <span className="pl-3 font-bold">
                  {examData &&
                    moment(examData.activeForm).format("DD MMM YYYY hh:mm")}
                </span>
              </div>
              <div>
                <span>Active_upto :-</span>
                <span className="pl-3 font-bold">
                  {examData &&
                    moment(examData.activeUpto).format("DD MMM YYYY hh:mm")}
                </span>
              </div>
              <div>
                <span>Mock Exam Status:-</span>
                <span className="ml-3">
                  <Checkbox id="agree" checked={examData && examData.mockExam} onChange={handleMockExamStatus}/>
                </span>
              </div>
              <div>
                <span>Allowed :-</span>
                <span className="ml-3">
                  <Checkbox id="agree" checked={examData && examData.allowed} />
                </span>
              </div>

              <div>
                <span>Show Result On Screen :-</span>
                <span className="ml-3">
                  <Checkbox
                    id="agree"
                    checked={examData && examData.showResultOnScreen}
                  />
                </span>
              </div>
              <div className="flex justify-between items-center">
                <h5 className=" font-bold tracking-tight text-gray-900 dark:text-white mb-1 mt-2">
                  Biometric
                </h5>
                <div>
                  {editable === false ? (
                    <HiPencil
                      className="text-blue-600"
                      onClick={() => {
                        setEditable(true);
                      }}
                    />
                  ) : (
                    <Button color="success" onClick={updateExam}>
                      Save
                    </Button>
                  )}
                </div>
              </div>
              <div>
                <div>
                  <span>Exam Shuffle :-</span>
                  <span className="ml-3">
                    <Checkbox
                      id="agree"
                      name="shuffling"
                      checked={examData && examData.shuffling}
                      onChange={handleCheckedValue}
                    />
                  </span>
                </div>
                <div>
                  <span>Face :-</span>
                  <span className="ml-3">
                    <Checkbox
                      id="agree"
                      name="face"
                      checked={examData && examData.face}
                      onChange={handleCheckedValue}
                    />
                  </span>
                </div>
                <div>
                  <span>Fingerprint :-</span>
                  <span className="ml-3">
                    <Checkbox
                      id="agree"
                      name="fingerPrint"
                      checked={examData && examData.fingerPrint}
                      onChange={handleCheckedValue}
                    />
                  </span>
                </div>
                <div>
                  <span>Iris :-</span>
                  <span className="ml-3">
                    <Checkbox
                      id="agree"
                      name="iris"
                      checked={examData && examData.iris}
                      onChange={handleCheckedValue}
                    />
                  </span>
                </div>
              </div>

              <div>
                <h5 className=" font-bold tracking-tight text-gray-900 dark:text-white mb-1 mt-2">
                  Instruction Data
                </h5>
                <div>
                  <span>Instruction :-</span>
                  <span className="ml-3">
                    <a
                      className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                      href={examData && examData.instructions}
                      download
                    >
                      {examData && examData.instructions}
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="px-10">
              <div>
                <h5 className=" font-bold tracking-tight text-gray-900 dark:text-white mb-5">
                  Instructions Upload
                </h5>
                <InstructionUpload handleUploadFile={handleUploadedFile} />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

export default ExamDetail
