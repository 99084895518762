import { HiArrowsUpDown, HiBookOpen } from "react-icons/hi2";
import IRouter from "../interfaces/IRouter";
import NormalizationShift from "../views/Exam/Normatization/NormalizationShift";
import ExamStatsIndex from "../views/Live/ExamStats/ExamStats.index";

const normalizationRouter: IRouter[] = [
    {
        path: "shift",
        element: <NormalizationShift />,
        navbarShow: true,
        name: "Shifts",
        icon: HiArrowsUpDown,
    },
];

export default normalizationRouter