
import { useState, useEffect } from 'react';


interface ITabsIndex {
    items: {
        label: string,
        value: string
    }[]
    onChange: any,
}

export default function TabsIndex(props: ITabsIndex) {

    const [active, setActive] = useState<number>(0);

    useEffect(() => {
        if(active>0 && props.items?.length>0){
            props.onChange(props.items[active],active)
        } else if (active==0 && props.items?.length>0){
            props.onChange(props.items[active],active);

        }
    },[active])


    const isActive = (label: string, index: number) => {
        return (
            <li className="mr-2">
                <a href="#" className="inline-block p-4 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500" aria-current="page" onClick={() => setActive(index)}>
                    {label}
                </a>
            </li>
        )
    }

    const isInActive = (label: string, index: number) => {
        return (
            <li className="mr-2">
                <a href="#" className="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" onClick={() => setActive(index )}>
                    {label}
                </a>
            </li>
        )
    }


    return (
        <> {props.items && props.items?.length > 0 ?
            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px">
                    {
                        props.items && props.items.length > 0 && props.items.map((item, index) => {
                            return (
                                active == index ? isActive(item.label, index) : isInActive(item.label, index)
                            )
                        })
                    }
                </ul>
            </div>
            : "Add Shifts to proceed"}
        </>
    )
}