import React, { useEffect, useState } from "react";
import { Button, Card, Spinner } from "flowbite-react";
import { useParams } from "react-router";

import toast from "react-hot-toast";
import ExamService from "../../../services/private/Exam/exam.service";
import ClockTable from "../../../components/Tables/ClockTable";


interface IClock {
  heading: string;
  isClicked?: boolean;
  name: string;
  inputName?: string;
  isRange?: boolean;
  isExamTime?: boolean;
  isDataSync?: boolean;
  isExamEndTime?: boolean;
  isExamStartTime?: boolean;
  inputExamStart?: string;
  inputExamEnd?: string;
}

const Clock = ({ heading, isClicked, name, inputName, isRange, isDataSync, isExamEndTime, isExamStartTime, inputExamEnd, inputExamStart, isExamTime }: IClock) => {
  const { examId } = useParams();

  const [data, setData] = useState<any>([]);

  const getData = async () => {
    await ExamService.getExamShifts(examId)
      .then((res: any) => {
        if (res.status === 200) {
          setData(res?.data);
        }
      })
      .catch((err) => {
        toast.error("Unable to get shifts!!");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="ml-10 mt-10">
        <div className="max-w-sm mx-5">
          <Card style={{ width: "25rem" }}>
            {data ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {heading}
                  </h5>
                </div>
                <ClockTable
                  tableData={data}
                  reload={getData}
                  isClicked={isClicked}
                  name={name}
                  inputName={inputName}
                  isRange={isRange}
                  isExamTime={isExamTime}
                  isDataSync={isDataSync}
                  isExamEndTime={isExamEndTime}
                  isExamStartTime={isExamStartTime}
                  inputExamEnd={inputExamEnd}
                  inputExamStart={inputExamStart}
                />{" "}
              </>
            ) : (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {heading}
                  </h5>
                </div>
                <ClockTable
                  tableData={[]}
                  reload={getData}
                  isClicked={isClicked}
                  name={name}
                  inputName={inputName}
                  isRange={isRange}
                  isExamTime={isExamTime}
                  isDataSync={isDataSync}
                  isExamEndTime={isExamEndTime}
                  isExamStartTime={isExamStartTime}
                  inputExamEnd={inputExamEnd}
                  inputExamStart={inputExamStart}
                />{" "}
              </>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};

export default Clock;
