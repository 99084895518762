import IRouter from "../../../interfaces/IRouter"
import { EditExamDropDownRouter } from "../../../Router";

interface IReservationIndex {
    children: IRouter[]
}

export default function ReservationIndex(props: IReservationIndex) {

    return (
        <>
            <EditExamDropDownRouter children={props.children} />
        </>
    )
}