import IRouter from "../interfaces/IRouter";
import Exams from "../views/Admin/Exams/Exams";

import { HiBolt, HiBookmark, HiCalendar, HiClipboardDocument, HiClock, HiCog8Tooth } from "react-icons/hi2";
import { HiOutlineUpload, HiVideoCamera } from "react-icons/hi";
import { SiMinetest } from "react-icons/si";


import { HiLink } from "react-icons/hi";
import dashboardNavigator from "../navigator/admin/dashboard.navigator";
import editexamrouter from "./editexam.router";
import ReservationIndex from "../views/Exam/Reservation/Reservation.index";
import EditExamIndex from "../views/Exam/EditExam/EditExam.index";
import UploadIndex from "../views/Exam/Uploads/Upload.index";
import uploadRoutes from "./upload.router";
import reservationrouter from "./reservation.router";
import hooksRoutes from "./hooks.router";
import HooksIndex from "../views/Exam/Hooks/Hooks.index";
import ClocksIndex from "../views/Exam/Clocks/clocks.index";
import clocksRouter from "./clock.router";
import resultRouter from "./results.router";
import normalizationRouter from "./normalization.router";
import centersRouter from "./centers.router";
import CentersIndex from "../views/Exam/Centers/centers.index";
import TestLiveExamIndex from "../views/Exam/TestLiveExams/TestLiveExam.index";
import testLiveExamsRouter from "./testLiveExams.router";
import liveExamSettingsRouter from "./live-exam.router";
import LiveExamSettingsIndex from "../views/Exam/LiveExamSettings/LiveExamSettings.index";
import { FaQuestion } from "react-icons/fa";
import { FinalAnswerSheetRouter } from "../Router";
import FinalAnswerSheetIndex from "../views/Exam/FinalAnswerSheet/FinalAnswerSheet.index";
import finalAnswerSheetRouter from "./finalAnswerSheet.router";

export const baseurl = "/exam/";

const examRoutes: IRouter[] = [
  {
    path: "live-settings",
    element: <LiveExamSettingsIndex children={liveExamSettingsRouter} />,
    navbarShow: true,
    name: "Live Settings",
    icon: HiVideoCamera,
    children: liveExamSettingsRouter,
    baseUrl: baseurl,
  },
  {
    path: "settings",
    element: <EditExamIndex children={editexamrouter} />,
    navbarShow: true,
    name: "Settings",
    icon: HiCog8Tooth,
    children: editexamrouter,
    baseUrl: baseurl,
  },
  {
    path: "uploads",
    element: <UploadIndex children={uploadRoutes} />,
    navbarShow: true,
    name: "Uploads",
    icon: HiOutlineUpload,
    baseUrl: baseurl,
    children: uploadRoutes,
  },
  {
    path: "reservations",
    element: <ReservationIndex children={reservationrouter} />,
    navbarShow: true,
    name: "Reservations",
    icon: HiCalendar,
    children: reservationrouter,
    baseUrl: baseurl,
  },
  {
    path: "hooks",
    element: <HooksIndex children={hooksRoutes} />,
    navbarShow: true,
    name: "Hooks",
    icon: HiLink,
    baseUrl: baseurl,
    children: hooksRoutes,
  },
  {
    path: "clocks",
    element: <ClocksIndex children={clocksRouter} />,
    navbarShow: true,
    name: "Clocks",
    icon: HiClock,
    children: clocksRouter,
    navigator: dashboardNavigator,
    baseUrl: baseurl,
  },
  {
    path: "results",
    element: <ClocksIndex children={resultRouter} />,
    navbarShow: true,
    name: "Results",
    icon: HiClipboardDocument,
    children: resultRouter,
    navigator: dashboardNavigator,
    baseUrl: baseurl,
  },
  {
    path: "normalizationFormula",
    element: <ClocksIndex children={normalizationRouter} />,
    navbarShow: true,
    name: "Normalization Formula",
    icon: HiBolt,
    children: normalizationRouter,
    navigator: dashboardNavigator,
    baseUrl: baseurl,
  },
  {
    path: "centers",
    element: <CentersIndex children={centersRouter} />,
    navbarShow: true,
    name: "Centers",
    icon: HiBolt,
    children: centersRouter,
    navigator: dashboardNavigator,
    baseUrl: baseurl,
  },
  {
    path: "test-live-exams",
    element: <TestLiveExamIndex children={testLiveExamsRouter} />,
    navbarShow: true,
    name: "Test for Live Exams",
    icon: SiMinetest,
    children: testLiveExamsRouter,
    navigator: dashboardNavigator,
    baseUrl: baseurl,
  },
  {
    path: "final-answer",
    element: <FinalAnswerSheetIndex children={finalAnswerSheetRouter}/>,
    navbarShow: true,
    name: "Final Answer Sheet",
    icon: FaQuestion,
    children: finalAnswerSheetRouter,
    navigator: dashboardNavigator,
    baseUrl: baseurl,
  },
];

export default examRoutes;
