import { Button, Card } from 'flowbite-react';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router';
import ExamService from '../../../services/private/Exam/exam.service';
import Select from "react-select";
import { ExportToCsv } from "export-to-csv-fix-source-map";



interface IOptions {
  fieldSeparator: any;
  quoteStrings: any;
  decimalSeparator: any;
  showLabels: any;
  showTitle: any;
  title: any;
  useTextFile: any;
  useBom: boolean;
  useKeysAsHeaders: boolean;
}



const ExmwallPassword = () => {
  const [shiftData, setShiftData] = useState<any>();
  const { examId } = useParams();
  const [selectedShift, setSelectedShift] = useState<any>({});
  const [userData,setUserData]=useState<any[]>([])
  const [isDownload, setIsDownload]=useState<boolean>();

  let csvOptions: IOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: "CSV Data",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleDownload = () => {
    if (userData?.length > 0) {
      csvExporter.generateCsv(userData);
    }
  };
  
  const getAllShift = async () => {
    await ExamService.getExamShifts(examId).then((res: any) => {
      if (res.status === 200) {
        setShiftData(
          res.data.map((data: any) => {
            return {
              value: data.shiftid,
              label: data.shiftname,
            };
          })
        );
      }
    });
  };

    const handlePassword=async()=>{

        ExamService.createPassword(examId,selectedShift.value).then((res)=>{
            if (res?.status === 200){
                setIsDownload(true);
                setUserData(res?.data);
                toast.success('created Password successfully')
            }
        }).catch((err)=>{
       
            toast.error('failed to create Password')

        })



    }


  useEffect(() => {
    getAllShift();
  }, []);

  return (
    <div className="my-10 mx-5">
      <div className="my-5">
        <Card>
          <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            1. Select Shift
          </h5>
          <Select
            options={shiftData}
            onChange={setSelectedShift}
            placeholder="Select the Shift name..."
          />
        </Card>
      </div>
      <div className="my-5">
        <Card>
          <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            2. Create Password
          </h5>
          <Button onClick={() => handlePassword()}>Create password</Button>
        </Card>
      </div>
     {isDownload && <div className="my-5">
        <Card>
          <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            3. Download CSV
          </h5>
          <Button size="sm" onClick={handleDownload}>
            Download
          </Button>
        </Card>
      </div>}
    </div>
  );
}
  

export default ExmwallPassword
