import axios, { Method } from "axios";
import toast from "react-hot-toast";
import axiosHandler from "./axiosHandler";

// export interface IParams {
//     value: any,
//     index: string
// }

export enum RequestMethod {
  POST = "post",
  GET = "get",
  PUT = "put",
  DELETE = "delete",
  PATCH = "patch",
}

export default async function makeRequest(
  url: any,
  method: any,
  inputPayload?: any
) {
  let requestConfig = {
    baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_VERSION}`,
    url: url,
    method: method,
    headers: {
      Authorization: localStorage.getItem("authkey-super") || "",
    },
    data: {},
  };

  if (method !== "get" && inputPayload) {
    requestConfig.data = inputPayload;
  }


  return await axios
    .request(requestConfig)
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
    })
    .catch((e) => {
      axiosHandler(e);
      toast.error(e.response.data || "Something Went Wrong!");
      return e;
    });
}


export function makeParams(params: any) {
  let paramString = "?";
  for (const param in params) {
    if (params[param].value) {
      if (Number(param) != 0) paramString = paramString + "&";
      paramString =
        paramString + params[param].index + "=" + params[param].value;
    }
  }
  return paramString;
}
