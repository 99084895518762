import makeRequest, { makeParams, RequestMethod } from "../../make.request";
import urls from "../../urls";

export default class LiveService {
  static async getResponses(examId: string) {
    return await makeRequest(
      urls.protected.live.reponses + "/" + examId,
      RequestMethod.GET
    );
  }

  static async getCenterCount(examId: string) {
    return await makeRequest(
      urls.protected.live.centerCount + "/" + examId,
      RequestMethod.GET
    );
  }

  static async verifyClockTimings(examId: any) {
    return await makeRequest(
      urls.protected.live.clockTimings + "/" + examId,
      RequestMethod.GET
    );
  }
  static async verifyQuestionPaper(examId: any) {
    return await makeRequest(
      urls.protected.live.questionPaper + "/" + examId,
      RequestMethod.GET
    );
  }
  static async verifyShift(examId: any) {
    return await makeRequest(
      urls.protected.live.shifts + "/" + examId,
      RequestMethod.GET
    );
  }
  static async verifyCandidatesForCenter(examId: any) {
    return await makeRequest(
      urls.protected.live.candidatesForCenter + "/" + examId,
      RequestMethod.GET
    );
  }
  static async verifyTimeBuffer(examId: any) {
    return await makeRequest(
      urls.protected.live.timeBuffer + "/" + examId,
      RequestMethod.GET
    );
  }
  static async verifyQpBankHooks(examId: any) {
    return await makeRequest(
      urls.protected.live.qpBankHooks + "/" + examId,
      RequestMethod.GET
    );
  }
  static async verifyBioUsers(examId: any) {
    return await makeRequest(
      urls.protected.live.bioUsers + "/" + examId,
      RequestMethod.GET
    );
  }
  static async verifyExmsteData(examId: any) {
    return await makeRequest(
      urls.protected.live.exmsteData + "/" + examId,
      RequestMethod.GET
    );
  }
  static async verifyZone(examId: any) {
    return await makeRequest(
      urls.protected.live.zone + "/" + examId,
      RequestMethod.GET
    );
  }
  static async verifySection(examId: any) {
    return await makeRequest(
      urls.protected.live.section + "/" + examId,
      RequestMethod.GET
    );
  }
  static async verifyAsymmetricKeys(examId: any) {
    return await makeRequest(
      urls.protected.live.asymmetricKeys + "/" + examId,
      RequestMethod.GET
    );
  }

  static async getCenterData(
    examId: string,
    status: string,
    shift: string,
    center: string,
    lastSynced: string
  ) {
    const params = makeParams([
      {
        index: "status",
        value: status,
      },
      {
        index: "center",
        value: center,
      },
      {
        index: "time",
        value: lastSynced,
      },
      {
        index: "shift",
        value: shift,
      },
    ]);
    return await makeRequest(
      urls.protected.live.online + "/" + examId + params,
      RequestMethod.GET
    );
  }
}