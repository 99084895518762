import { Table } from 'flowbite-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { HiPencil } from 'react-icons/hi'
import { HiArchiveBoxXMark } from 'react-icons/hi2'
import { useParams } from 'react-router'
import ExamService from '../../services/private/Exam/exam.service'
import EditShiftModal from '../Modals/EditShift.modal'

interface IShiftTable {
  tableData: any
  reload: any
}

const ShiftTable = ({ tableData, reload }: IShiftTable) => {

  const [shiftIndex, setShiftIndex] = useState<number>(-1);

  const params: any = useParams();
  useEffect(() => {
    reload()
  }, [shiftIndex])

  const deleteShift = async (shiftId: any) => {
    await ExamService.deleteShift(params.examId, shiftId)
      .then((res: any) => {
        if (res.status === 200) {
          toast.success("Shift deleted");
          reload();
        }
      })
      .catch((err) => {
        toast.error("Unable to delete shift");
      });
  };

  return (
    <div>
      <Table>
        <Table.Head>
          <Table.HeadCell>
            Sr. No.
          </Table.HeadCell>
          <Table.HeadCell>
            Shift Name
          </Table.HeadCell>
          <Table.HeadCell>
            Shift Start Time
          </Table.HeadCell>
          <Table.HeadCell>
            Shift End Time
          </Table.HeadCell>
          <Table.HeadCell>
            Action
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {tableData && tableData.length > 0 ? tableData.map((data: any, index: number) => {
            return (
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell>
                  {index + 1}
                </Table.Cell>
                <Table.Cell>
                  {data ? data.shiftname : "--"}
                </Table.Cell>
                <Table.Cell>
                  {data ? moment(data.shiftstartdateandtime).format("MMM DD YYYY hh:mm a") : "--"}

                </Table.Cell>
                <Table.Cell>
                  {data ? moment(data.shiftenddateandtime).format("MMM DD YYYY hh:mm a") : "--"}
                </Table.Cell>

                <Table.Cell className='flex justify-start'>
                  <span
                    className="mr-3"
                    onClick={() => {
                      setShiftIndex(index)
                    }}
                  >
                    <HiPencil className="text-blue-600" />
                  </span>
                  <span
                    onClick={() => { deleteShift(data.shiftid) }}
                  >
                    <HiArchiveBoxXMark className='text-red-600 text-lg' />
                  </span>
                </Table.Cell>
              </Table.Row>
            )
          }) : "No data available"}
        </Table.Body>
      </Table>
      <EditShiftModal
        show={shiftIndex >= 0 ? true : false}
        onClose={() => setShiftIndex(-1)}
        data={tableData && tableData[shiftIndex]}
      />
    </div>
  )
}

export default ShiftTable