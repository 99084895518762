import { Button, Label, Modal, TextInput } from 'flowbite-react'
import React from 'react'

interface IBufferModal {
    show: any,
    onClose: any,
    onChange: any,
    handleSubmission: any,
}

const BufferModal = ({ show, onClose, onChange, handleSubmission }: IBufferModal) => {
    return (
        <>
            <Modal
                show={show}
                size="md"
                popup={show}
                onClose={onClose}
            >
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                            Add Buffer
                        </h3>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="Time (in minutes)"
                                    value="Time (in minutes)"
                                />
                            </div>
                            <TextInput
                                id="number"
                                name="timeBuffer"
                                required={true}
                                onChange={onChange}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="password"
                                    value="Category"
                                />
                            </div>
                            <TextInput
                                type="text"
                                name="category"
                                required={true}
                                onChange={onChange}
                            />
                        </div>
                        <div className="w-full">
                            <Button onClick={handleSubmission}>
                                Create Buffer
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BufferModal