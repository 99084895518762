import { HiBookOpen, HiCog, HiComputerDesktop } from "react-icons/hi2";
import IRouter from "../interfaces/IRouter";
import ExamStatsIndex from "../views/Live/ExamStats/ExamStats.index";

const resultRouter: IRouter[] = [
  
    {
        path: "showResultOnScreen",
        element: <ExamStatsIndex />,
        navbarShow: true,
        name: "Show Results On-screen",
        icon: HiComputerDesktop,
    },
];

export default resultRouter