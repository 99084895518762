import IRouter from "../../../interfaces/IRouter"
import { EditLiveExamDropDownRouter } from "../../../Router"

interface ICentersIndex {
    children: IRouter[]
}

const CentersIndex = (props: ICentersIndex) => {
    return (
        <>
            <EditLiveExamDropDownRouter children={props.children} />
        </>
    )
}

export default CentersIndex;

