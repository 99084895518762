import React from "react";
import DatePicker from "react-datepicker";
import { Button, Label, Modal, TextInput } from "flowbite-react";
import moment from "moment";

interface IShift {
  show: any;
  setShow: any;
  onNewShiftDataChange: any;
  addNewShift: any;
  newShiftData:any
}

const CreateShiftModal = ({
  show,
  setShow,
  onNewShiftDataChange,
  addNewShift,
  newShiftData
}: IShift) => {
  return (
    <>
      <Modal show={show} size="md" onClose={() => setShow(false)}>
        <Modal.Header>
          Create Shift
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="Shift Name" value="Shift Name" />
              </div>
              <TextInput
                id="Shift Name"
                type="text"
                name="name"
                required={true}
                onChange={(e: any) => {
                  onNewShiftDataChange(e.target.value, "name");
                }}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="New Shift Start Date"
                  value="New Shift Start Date"
                />
              </div>
              <DatePicker
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                dateFormat="MMM d, yyyy h:mm aa"

                onChange={(e: any) => {
                  onNewShiftDataChange(
                   e,
                    "shiftStartDateAndTime"
                  );
                }}
                selected={newShiftData.shiftStartDateAndTime}
                showTimeSelect
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="Shift End Date and Time"
                  value="Shift End Date and Time"
                />
              </div>
              <DatePicker
                dateFormat="MMM d, yyyy h:mm aa"

                onChange={(e: any) => {
                  onNewShiftDataChange(
                    e,
                    "shiftEndDateAndTime"
                  );
                }}
                selected={newShiftData.shiftEndDateAndTime}
                showTimeSelect
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="w-full flex gap-7">
              <Button onClick={addNewShift}>Add</Button>
              <Button onClick={() => setShow(false)}>Cancel</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      ;
    </>
  );
};

export default CreateShiftModal;
