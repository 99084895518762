
export default class Auth {
    static checkAuth() {
        return localStorage.getItem("authkey-super") ? true : false
    }
    static clearAuthToken() {
        localStorage.clear();
        return true;
    }

    static setToken(token: any) {
        localStorage.setItem("authkey-super", token);
        return true;
    }
    static setUser(user: any) {
        localStorage.setItem("user", JSON.stringify(user))
        return true;
    }

    static signout() {
        console.log("error")
        return localStorage.clear();
    }
}
