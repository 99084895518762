import { Table } from 'flowbite-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { HiPencil } from 'react-icons/hi'
import { HiArchiveBoxXMark } from 'react-icons/hi2'
import EditClockModal from '../Modals/EditClock.modal';

interface IClockTable {
  tableData: any;
  reload: any;
  isRange?: boolean;
  isDataSync?: any;
  isClicked?: any;
  isExamStartTime?: any;
  isExamEndTime?: any;
  name: string;
  inputName?: any;
  inputExamStart?: any;
  inputExamEnd?: any;
  isExamTime?: boolean
}
const config = ["Shift Start Time", "Shift End Time"];

const examConfig = ["Start Time", "End Time"];


const ClockTable = ({
  tableData,
  reload,
  isRange,
  isDataSync,
  isClicked,
  isExamStartTime,
  isExamEndTime,
  name,
  inputName,
  inputExamStart,
  inputExamEnd,
  isExamTime
}: IClockTable) => {
  const [clockIndex, setClockIndex] = useState<number>(-1);

  useEffect(() => {
    reload();
  }, [clockIndex]);

  console.log(isRange, "isRange")

  return (
    <div>
      <Table>
        <Table.Head>
          <Table.HeadCell>Sr. No.</Table.HeadCell>
          <Table.HeadCell>Shift Id.</Table.HeadCell>
          <Table.HeadCell>Shift Name</Table.HeadCell>
          {isRange && (
            config.map((data) => {
              return <Table.HeadCell>{data}</Table.HeadCell>;
            })
          )}
          {isExamTime && (
            examConfig.map((data) => {
              return <Table.HeadCell>{data}</Table.HeadCell>;
            })
          )}
          <Table.HeadCell>Action</Table.HeadCell>
          <Table.HeadCell>
            <span className="sr-only">Edit</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {tableData && tableData.length > 0
            ? tableData.map((data: any, index: number) => {
              return (
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{data ? data.shiftid : "--"}</Table.Cell>
                  <Table.Cell>{data ? data.shiftname : "--"}</Table.Cell>
                  {isRange && (
                    <>
                      <Table.Cell>
                        {data?.shiftstartdateandtime
                          ? moment(data.shiftstartdateandtime).format(
                            "MMM DD YYYY hh:mm a"
                          )
                          : "--"}
                      </Table.Cell>
                      <Table.Cell>
                        {data?.shiftenddateandtime
                          ? moment(data?.shiftenddateandtime).format(
                            "MMM DD YYYY hh:mm a"
                          )
                          : "--"}
                      </Table.Cell>
                    </>
                  )}
                  {isExamTime && (
                    <>
                      <Table.Cell>
                        {data.examstarttime
                          ? moment(data.examstarttime).format(
                            "MMM DD YYYY hh:mm a"
                          )
                          : "--"}
                      </Table.Cell>
                      <Table.Cell>
                        {data.examendtime
                          ? moment(data.examendtime).format(
                            "MMM DD YYYY hh:mm a"
                          )
                          : "--"}
                      </Table.Cell>
                    </>
                  )}
                  {isDataSync && (
                    <Table.Cell>
                      {data.synctime ? data?.synctime : "--"}
                    </Table.Cell>
                  )}
                  {isClicked && (
                    <Table.Cell>
                      {
                        (name === "Exam End Time" &&
                          data?.examendtime) ||
                          (name === "Exam Start Time" &&
                            data?.examstarttime) ||
                          (name === "Biometric Start Time" &&
                            data?.biometricstarttime) ||
                          (name === "Candidate Data time" &&
                            data?.candidatedatetime) ||
                          (name === "Candidate Start time" &&
                            data?.candidatestarttime) ||
                          (name === "ExamSte Download Time" &&
                            data?.examstedownloadtime) ||
                          (name === "Gate closing time" &&
                            data?.gateclosingtime) ||
                          (name === "Live Exam start Time" &&
                            data?.liveexamstarttime) ||
                          (name === "Login Time" && data?.logintime) ||
                          (name === "Minimum Submission Time" &&
                            data?.minimumsubmissiontime) ||
                          (name === "QP delivery time" && data?.qpdeliverytime) ||
                          (name === "User data delivery time" &&
                            data?.userdatadeliverytime)
                          ? moment(
                            (name === "Biometric Start Time" &&
                              data?.biometricstarttime) ||
                            (name === "Candidate Data time" &&
                              data?.candidatedatetime) ||
                            (name === "Candidate Start time" &&
                              data?.candidatestarttime) ||
                            (name === "ExamSte Download Time" &&
                              data?.examstedownloadtime) ||
                            (name === "Gate closing time" &&
                              data?.gateclosingtime) ||
                            (name === "Live Exam start Time" &&
                              data?.liveexamstarttime) ||
                            (name === "Login Time" && data?.logintime) ||
                            (name === "Minimum Submission Time" &&
                              data?.minimumsubmissiontime) ||
                            (name === "QP delivery time" &&
                              data?.qpdeliverytime) ||
                            (name === "User data delivery time" &&
                              data?.userdatadeliverytime)
                          ).format("MMM DD YYYY hh:mm a")
                          : "--"}
                    </Table.Cell>
                  )}

                  <Table.Cell className="flex justify-start">
                    <span
                      className="mr-3"
                      onClick={() => {
                        setClockIndex(index);
                      }}
                    >
                      <HiPencil className="text-blue-600" />
                    </span>
                  </Table.Cell>
                </Table.Row>
              );
            })
            : "No Shifts found"}
        </Table.Body>
      </Table>
      <EditClockModal
        show={clockIndex >= 0 ? true : false}
        onClose={() => setClockIndex(-1)}
        data={tableData && tableData[clockIndex]}
        name={name}
        isRange={isRange}
        isExamTime={isExamTime}
        isDataSync={isDataSync}
        isClicked={isClicked}
        inputName={inputName}
        reload={reload}
        isExamStartTime={isExamStartTime}
        isExamEndTime={isExamEndTime}
        inputExamEnd={inputExamEnd}
        inputExamStart={inputExamStart}
      />
    </div>
  );
};

export default ClockTable