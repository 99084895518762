import ExamSidebar from "../../components/Sidebar/Exam.sidebar";

interface IExamIndex {
    render: any
}

export default function ExamIndex({ render }: IExamIndex) {
    return (
        <>
            <ExamSidebar render={render} />
        </>
    )
}