import { Button, Modal } from 'flowbite-react'
import React from 'react'

interface ICenterData {
    onClose: any,
    show: any
}

const CenterDataModal = ({ onClose, show }: ICenterData) => {
    return (
        <Modal
            show={show}
            size="lg"
            popup={true}
            onClose={onClose}
        >
            <Modal.Header />
            <Modal.Body>
                <div className="text-center">
                    {/* <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" /> */}
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        Are you sure you want to delete this product?
                    </h3>
                    <div className="flex justify-center gap-4">
                        <Button
                            color="failure"
                            onClick={() => { }}
                        >
                            Yes, I'm sure
                        </Button>
                        <Button
                            color="gray"
                            onClick={() => { }}
                        >
                            No, cancel
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CenterDataModal