import { Button,Label, Modal, TextInput } from "flowbite-react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

interface ICreateCenterEditModal {
  show: boolean;
  setShow: any;
  centerData: any;
  handleDataSave: any;
}

const CreateCenterEditModal = (props: ICreateCenterEditModal) => {

  const [selectedData, setSelectedData] = useState<any>({});

  const handleChange = (e:any) => {
    setSelectedData({...selectedData, [e.target.name]: e.target.value})
  }

  const saveData = () => {
    props.handleDataSave(selectedData);
    props.setShow(undefined);
  }
  
  useEffect(() => {
    setSelectedData(props.centerData)
  },[props.centerData])

  return (
    <React.Fragment>
      <Modal show={props.show} onClose={ () => props.setShow(undefined)}>
        <Modal.Header>Edit Details</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="exmsteServers" value="SERVER COUNT" />
              </div>
              <TextInput
                type={"number"}
                id="exmsteServers"
                required
                onChange={handleChange}
                name="serverCount"
                value={selectedData?.serverCount}
                min={0}
              />
            </div>

            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="exmbioDevices"
                  value="BIOMETRIC DEVICES COUNT PER SERVER"
                />
              </div>
              <TextInput
                type={"number"}
                id="biometricDevicesPerServer"
                required
                onChange={handleChange}
                name="biometricDevicesPerServer"
                value={selectedData?.biometricDevicesPerServer}
                min={0}
              />
            </div>

            <div className="mt-3 flex flex-row justify-end gap-2">
              <Button
                onClick={saveData}
              >
                Update
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default CreateCenterEditModal;
