import IRouter from "../interfaces/IRouter";
import { HiBuildingOffice } from 'react-icons/hi2';
import CenterStudentsIndex from '../views/Exam/Reservation/CentersStudents/CenterStudents.index';
import CenterLabsIndex from "../views/Exam/Reservation/Centerslabs/CenterLabs.index";
import { HiDesktopComputer, HiUser } from "react-icons/hi";
import LabAllocationIndex from "../views/Exam/Reservation/LabAllocation/LabAllocation.index";

const reservationrouter: IRouter[] = [
  {
    path: "center-student",
    element: <CenterStudentsIndex />,
    navbarShow: true,
    name: "Students",
    icon: HiUser,
  },
  // {
  //   path: "lab-allocation",
  //   element: <LabAllocationIndex/>,
  //   navbarShow: true,
  //   name: "Lab Allocation",
  //   icon: HiDesktopComputer,
  // }
]

export default reservationrouter;