import IRouter from "../interfaces/IRouter";
import { HiArrowPath, HiBookOpen, HiBriefcase, HiCog8Tooth, HiSignal, HiUser } from 'react-icons/hi2';
import ToggleIndex from "../views/Exam/LiveExamSettings/Toggle/Toggle.index";

const liveExamSettingsRouter: IRouter[] = [
  {
    path: "toggle",
    element: <ToggleIndex />,
    navbarShow: true,
    name: "Settings",
    icon: HiCog8Tooth,
  },
 
];

export default liveExamSettingsRouter;