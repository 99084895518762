import { Alert, Button, Card } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { HiEye, HiInformationCircle } from 'react-icons/hi'
import { useNavigate, useParams } from 'react-router'
import Select from "react-select"
import LoadingSpinner from '../../../components/Spinner/Loading.spinner'
import ExamService from '../../../services/private/Exam/exam.service'

const StudentUpload = () => {
  const { examId } = useParams();

  const navigation = useNavigate();
  const [shiftData, setShiftData] = useState<any>();
  const [shiftId, setShiftId] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false)


  console.log(shiftId);
  const handleSelectValue = (e: any, name: string) => {
    setShiftId({ ...shiftId, [name]: e.value })
  }


  const options = [
    {
      value: "exmreg",
      label: "EXMREG Auto Sync"
    },
    {
      value: "manual",
      label: "Manual CSV Upload"
    }
  ]

  const getAllShift = async () => {
    await ExamService.getExamShifts(examId).then((res: any) => {
      if (res.status === 200) {
        setShiftData(res.data.map((data: any) => {
          return {
            value: data.shiftid,
            label: data.shiftname
          }
        }))
      }
    })
  }



  const handleUploadedFile = async (files: any) => {
    let formData = new FormData();
    formData.append("upload", files.target.files[0]);
    console.log(examId, shiftId.id, formData, "upload");

    toast.promise(ExamService.uploadUserDataZip(examId, shiftId.id, formData).then((res) => {
      if (res.status === 200) {
        toast.success("Upload Complete");
      }
    }).catch((err) => {
      toast.error("Something went wrong");
    }), {
      loading: 'Uploading...',
      success: "Upload Complete",
      error: "Something went wrong"
    })

    setLoading(false);
  }

  const handleUploadUserCSV = async (files: any) => {
    let formData = new FormData();
    formData.append("upload", files.target.files[0]);
    await ExamService.uploadUsersCSV(examId, shiftId.id, formData).then((res) => {
      if (res.status === 200) {
        toast.success("Upload completed");
      }
    }).catch(err => {
      toast.error("Unable to uplaod files");
    })
    // toast.promise(ExamService.uploadUsersCSV(examId, shiftId.id, formData).then((res) => {
    // }), {
    //   loading: 'Uploading...',
    //   success: "Upload Complete",
    //   error: "Something went wrong"
    // })

    setLoading(false);
  }


  useEffect(() => {
    getAllShift();
  }, [])

  const [selectValue, setSelectValue] = useState<any>(options[0]);


  return (
    <>
      <div className="m-3">
        <Card className="w-full">
          <h5 className="m-2 text-2sm font-bold tracking-tight text-gray-900 dark:text-white">
            1. Select data Source
          </h5>
          <Select
            options={options}
            onChange={(e: any) => setSelectValue(e)}
            value={selectValue}
          />
        </Card>
        {selectValue.value === "exmreg" && (
          <div className="mt-2">
            <Alert
              color="info"
              additionalContent={
                <>
                  <div className="mt-2 mb-4 text-sm text-blue-700 dark:text-blue-800">
                    Make sure you have added API key and EXMREG Url in Hooks{" "}
                    {"->"} EXMREG section
                  </div>
                </>
              }
              icon={HiInformationCircle}
            >
              <h3 className="text-lg font-medium text-blue-700 dark:text-blue-800">
                Info
              </h3>
            </Alert>
            <div className="mt-5">
              <Card>
                <h3 className="m-2 text-2sm font-bold tracking-tight text-gray-900 dark:text-white">
                  Select Shift name
                </h3>
                <Select
                  options={shiftData}
                  onChange={(e: any) => handleSelectValue(e, "id")}
                // value={selectValue}
                />
              </Card>
            </div>
            <div>
              <div className="mt-2">
                <div className="flex items-center justify-center w-full">
                  <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="w-10 h-10 mb-3 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        .csv{" "}
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      onChange={handleUploadUserCSV}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}

        {selectValue.value === "manual" && (
          <>
            <div className="mt-2">
              <Alert
                color="info"
                additionalContent={
                  <>
                    <div className="mt-2 mb-4 text-sm text-blue-700 dark:text-blue-800">
                      Only Zip Uploads are allowed. To download Sample format{" "}
                      <a
                        className="text-underline"
                        href="https://exmcld-public-static.s3.ap-south-1.amazonaws.com/sample_exmcld_student_data.zip"
                      >
                        <u>click here</u>
                      </a>
                    </div>
                  </>
                }
                icon={HiInformationCircle}
              >
                <h3 className="text-lg font-medium dark:text-blue-800">
                  Info
                </h3>
              </Alert>
            </div>
            <div className="mt-5">
              <Card>
                <h3 className="m-2 text-2sm font-bold tracking-tight text-gray-900 dark:text-white">
                  Select Shift name
                </h3>
                <Select
                  options={shiftData}
                  onChange={(e: any) => handleSelectValue(e, "id")}
                // value={selectValue}
                />
              </Card>
            </div>
            <div className="mt-2">
              <div className="flex items-center justify-center w-full">
                <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      aria-hidden="true"
                      className="w-10 h-10 mb-3 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span>{" "}
                      or drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      .zip{" "}
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={handleUploadedFile}
                  />
                </label>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default StudentUpload;


