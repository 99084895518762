import React, { useEffect, useState } from 'react'
import { Button, Label, Modal, TextInput } from 'flowbite-react'
import ExamService from '../../services/private/Exam/exam.service';
import { useParams } from 'react-router';
import { toast } from 'react-hot-toast';


interface IEditNormalization {
  show: any;
  setShow: any;
  reload: any;
  data:any;
  index:any;
}


const EditNormalizationModal = ({
  show,
  setShow,
  reload,
  data,
  index,
}: IEditNormalization) => {
  const { examId }: any = useParams();
  const [factor, setFactor] = useState<any>();

  const onUpdateShift = async () => {
    let payload = {
      shiftname: factor.shiftname,
      shiftstartdateandtime: factor.shiftstartdateandtime,
      shiftenddateandtime: factor.shiftenddateandtime,
      normalization: factor.normalization,
    };

    await ExamService.updateShift(examId, factor.shiftid, payload).then(
      (res) => {
        if (res.status === 200) {
          setShow(false);
          setFactor(res?.data);
          toast.success("Updated Values");
          reload();
        }
      }
    );
  };

  useEffect(() => {
    setFactor(data && data[index]);
  }, [index]);

  return (
    <>
      <Modal show={show} size="md" popup={show} onClose={() => setShow(false)}>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              Edit Factor
            </h3>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="Factor" value="Factor" />
              </div>
              <TextInput
                type="number"
                name="Factor"
                defaultValue={factor?.normalization || 0}
                onChange={(e: any) => {
                  console.log(factor);
                  setFactor((prev: any) => ({
                    ...prev,
                    normalization: Number(e.target.value),
                  }));
                }}
                min='0'
                required={true}
              />
            </div>
            <div className="w-full">
              <Button
                onClick={() => {
                  setShow(false);
                  reload();
                  onUpdateShift();
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditNormalizationModal