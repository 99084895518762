import IRouter from "../interfaces/IRouter";
import { HiBookOpen } from "react-icons/hi2";
import ExamStatsIndex from "../views/Live/ExamStats/ExamStats.index";
import CandidateDevices from "../views/Monitoring/CandidateDevices/CandidateDevices.index";
import CandidateTimings from "../views/Monitoring/CandidateTImings/CandidateTimings.index";

const monitoringcandidatedevicesrouter: IRouter[] = [
  {
    path: "candidate-devices",
    element: <CandidateDevices />,
    navbarShow: true,
    name: "Candidate Devices",
    icon: HiBookOpen,
  },

  {
    path: "candidate-timings",
    element: <CandidateTimings />,
    navbarShow: true,
    name: "Candidate Timings",
    icon: HiBookOpen,
  },
];

export default monitoringcandidatedevicesrouter;
