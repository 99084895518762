import IRouter from "../interfaces/IRouter";
import { HiArrowPath, HiBookOpen, HiBriefcase, HiCog8Tooth, HiSignal, HiUser } from 'react-icons/hi2';
import SettingIndex from "../views/Exam/Settings/setting.index";
import ShiftIndex from "../views/Exam/Shifts/shifts.index";
import BufferIndex from "../views/Exam/Buffer/Buffer.index";
import ClientAccountIndex from "../views/Exam/ClientAccount/clientAccount.index";
import SectionIndex from "../views/Exam/Sections/section.index";
import CenterSettingsIndex from "../views/Exam/CenterSettings/CenterSettings.index";
import CenterSettings from "../views/Exam/CenterSettings/CenterSettings";
import ExamDetailIndex from "../views/Exam/ExamDetail/ExamDetail.index";
import { HiLocationMarker } from "react-icons/hi";
import ZoneIndex from "../views/Exam/Zone/Zone.index";

const editexamrouter: IRouter[] = [
  {
    path: "shifts",
    element: <ShiftIndex />,
    navbarShow: true,
    name: "Shifts",
    icon: HiBookOpen,
  },
  // {
  //   path: "sections",
  //   element: <SectionIndex />,
  //   navbarShow: true,
  //   name: "Sections",
  //   icon: HiBriefcase,
  // },
  {
    path: "buffer",
    element: <BufferIndex />,
    navbarShow: true,
    name: "Buffer",
    icon: HiSignal,
  },
  // {
  //   path: "client-account",
  //   element: <ClientAccountIndex />,
  //   navbarShow: true,
  //   name: "Client-Account",
  //   icon: HiUser,
  // },
  {
    path: "center-settings",
    element: <CenterSettings />,
    navbarShow: true,
    name: "Center Settings",
    icon: HiArrowPath,
  },

  {
    path: "examDetails",
    element: <ExamDetailIndex />,
    navbarShow: true,
    name: "Exam Details",
    icon: HiBookOpen,
  },
  {
    path: "zone",
    element: <ZoneIndex />,
    navbarShow: true,
    name: "Zones",
    icon: HiLocationMarker,
  },
];

export default editexamrouter;