import React from 'react'
import { Button, Label, Modal, TextInput } from 'flowbite-react'

interface IEditHooksModal {
    show: any,
    onClose: any,
    stateData: any
    onChange: any,
    onSubmission?: any
}

const EditHooksModal = ({ show, onClose, stateData, onChange, onSubmission }: IEditHooksModal) => {
    console.log(stateData, "exmbnkData");


    const handleChange = (e: any) => {
        onChange({...stateData, [e.target.name]: e.target?.value});
    }

    return (
        <>
            <Modal
                show={show}
                size="md"
                popup={show}
                onClose={onClose}
            >
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                            Edit Hooks
                        </h3>
                        <div>
                            <form>
                                <div>
                                    {stateData && Object.keys(stateData).map((data: any) => {
                                        return (
                                            <div className='mb-6'>
                                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{data}</label>
                                                <input name={data} onChange={handleChange} value={stateData[data]} type="text" id="last_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                            </div>
                                        )
                                    })}
                                </div>
                            </form>
                        </div>
                        <div className="w-full">
                            <Button onClick={onSubmission}>
                                Update Hook
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditHooksModal