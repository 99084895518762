import React, { useEffect, useState } from 'react'
import { Button, Label, Modal, TextInput } from 'flowbite-react'
import Select from "react-select"
import ExamService from '../../services/private/Exam/exam.service'
import { useParams } from 'react-router'
import { toast } from 'react-hot-toast'

interface ICreateSectionModal {
    show: any,
    onClose: any,
}

const CreateSectionModal = (props: ICreateSectionModal) => {

    const { examId } = useParams();
    const [shiftData, setShiftData] = useState<any>();

    const [selectedShift, setSelectedShift] = useState<any>();
    const [sectionData, setSectionData] = useState<any>({
        sectionName: " ",
        questionPaperId: " ",
        shiftId: " "
    });


    const getAllShift = async () => {
        await ExamService.getExamShifts(examId).then((res: any) => {
            if (res.status === 200) {
                setShiftData(res.data);
            }
        }).catch(err => {
            toast.error("Unable to fetch Shifts.")
        })
    }

    const onChangeValue = (e: any) => {
        setSectionData({ ...sectionData, [e.target.name]: e.target.value })
    }

    const onAddSection = async () => {
        let payload = {
            shiftId: selectedShift.value,
            questionPaperId: sectionData.questionPaperId,
            sectionName: sectionData.sectionName
        };

        await ExamService.addSection(examId, payload).then(res => {
            if (res.status === 200) {
                props.onClose();
                toast.success("Section Added")
                setSelectedShift({});
                setSectionData({})
            }
        }).catch(e => {
            toast.error(e.response.data || "Unable to update section");
        })
    }

    useEffect(() => {
        getAllShift();
    }, [])


    return (
        <>
            <Modal
                show={props.show}
                size="md"
                popup={true}
                onClose={props.onClose}
            >
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                            Create Section
                        </h3>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    value="Section Name"
                                />
                            </div>
                            <TextInput
                                type="text"
                                name="sectionName"
                                onChange={onChangeValue}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    value="QP Id"
                                />
                            </div>
                            <TextInput
                                type="text"
                                name="questionPaperId"
                                onChange={onChangeValue}
                            />
                        </div>

                        <div>
                            <div className="mb-2 block">
                                <Label
                                    value="Shift"
                                />
                            </div>
                            <Select
                                options={shiftData && shiftData.map((shift: any) => {
                                    return {
                                        value: shift.shiftid,
                                        label: shift.shiftname
                                    }
                                })}
                                value={selectedShift}
                                onChange={setSelectedShift}
                            />
                        </div>

                        <div className="w-full">
                            <Button onClick={onAddSection}>
                                Create Section
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateSectionModal