import React from 'react'
import Shift from './Shift'

const ShiftIndex = () => {
    return (
        <>
            <Shift />
        </>
    )
}

export default ShiftIndex