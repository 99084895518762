import React from 'react'
import ExamDetail from './ExamDetail';

const ExamDetailIndex = () => {
  return (
    <div>
      <ExamDetail/>
    </div>
  )
}

export default ExamDetailIndex
