import React from 'react'
import Section from './Section'

const SectionIndex = () => {
    return (
        <>
            <Section />
        </>
    )
}

export default SectionIndex