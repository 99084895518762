import { Button, Card } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import EditHooksModal from '../../../../components/Modals/EditHooks.modal'
import ExamService from '../../../../services/private/Exam/exam.service';

const Exmcen = () => {
    const { examId } = useParams();

    const [exmcenData, setExmcenData] = useState<any>({});
    const [showCenModal, setShowCenModal] = useState<boolean>(false);



    const getHooksData = async () => {
        await ExamService.getHooksData(examId).then((res: any) => {
            if (res.status === 200) {
                setExmcenData({
                    EXMCEN: res.data.EXMCEN,
                    EXMCEN_KEY: res.data.EXMCEN_KEY
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    const onUpdate = async () => {

        await ExamService.updateExamUrls(examId, { EXMCEN: exmcenData.EXMCEN, EXMCEN_KEY: exmcenData.EXMCEN_KEY }).then((res) => {
            if (res.status === 200) {
                toast.success("Succesfully Updated");
                setShowCenModal(false);
                getHooksData();
            }
        }).catch((err) => {
            toast.error("Failed to Update");
        });
    };


    useEffect(() => {
        getHooksData();
    }, []);


    return (
        <div className="ml-10 mt-10">
            <Card>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        EXMCEN
                    </h5>
                    <Button onClick={() => setShowCenModal(true)}>
                        Edit
                    </Button>
                </div>
                <Card>
                    <div>
                        <p className="font-bold text-gray-700 dark:text-gray-400">
                            URL: {" "}{" "}{exmcenData ? exmcenData.EXMCEN : "--"}
                        </p>
                        <p className="font-bold text-gray-700 dark:text-gray-400">
                            KEY: {" "}{" "}{exmcenData ? exmcenData.EXMCEN_KEY : "--"}
                        </p>
                    </div>
                </Card>
            </Card>
            <EditHooksModal
                show={showCenModal}
                stateData={exmcenData}
                onChange={(new_state: any) => { setExmcenData(new_state) }}
                onClose={() => setShowCenModal(false)}

                onSubmission={onUpdate}
            />
        </div>
    )
}

export default Exmcen