import { Badge, Dropdown, Table } from 'flowbite-react'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { HiArchiveBoxXMark, HiCheckCircle, HiXCircle } from 'react-icons/hi2'
import { AiFillSetting } from 'react-icons/ai'

import ExamService from '../../services/private/Exam/exam.service'
import { idText } from 'typescript'
import PasswordModal from '../Modals/Password.modal'
import { FaSlack } from 'react-icons/fa'

interface IZonesTable {
    tableData: any,
    reload: any
}

const ZonesTable = ({ tableData, reload }: IZonesTable) => {

    const [password, setPassword] = useState<any>(undefined);


    const handleDeleteZones = async (zoneId: string) => {
        await ExamService.deleteZone(zoneId).then((res: any) => {
            if (res.status === 200) {
                toast.success("Zone deleted");
                reload();

            }
        }).catch(err => {
            toast.error("Unable to delete zone")
        })
    }

    const handleGeneratePassword = async (zoneId: any) => {
        await ExamService.generatePassword(zoneId).then((res) => {
            if (res.status === 200) {
                toast.success("Password Generated Successfully");
                setPassword(res.data);
            }
        }).catch(err => {
            toast.error("Unable to delete zone")
        })
    }

    return (
        <>
            <PasswordModal
                show={password}
                onClose={() => setPassword(false)}
            />
            <Table hoverable={true}>
                <Table.Head>
                    <Table.HeadCell>
                        S.No.
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Zone Name
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Centers
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Shift Id
                    </Table.HeadCell>
                    <Table.HeadCell>
                        User Name
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Active
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Action
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body>
                    {tableData && tableData.length > 0 ? tableData.map((zone: any, index: any) => {
                        return (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={index}>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    {index + 1}
                                </Table.Cell>
                                <Table.Cell>
                                    {zone ? zone.name : "--"}
                                </Table.Cell>
                                <Table.Cell>

                                    {zone && zone?.centers?.map((center: any) => {
                                        return (
                                            <div>
                                                <Badge
                                                    color="gray"
                                                    size="sm"
                                                    className='mt-2'
                                                >
                                                    {center}
                                                </Badge>
                                            </div>
                                        )
                                    })}
                                </Table.Cell>
                                <Table.Cell>
                                    {zone ? zone.shiftId : "--"}
                                </Table.Cell>
                                <Table.Cell>
                                    {zone ? zone.username : "--"}
                                </Table.Cell>
                                <Table.Cell>
                                    {zone && zone.isActive === true ? <HiCheckCircle className="text-green-600 text-lg" /> : <HiXCircle className="text-red-600 text-lg" />}
                                </Table.Cell>
                                <Table.Cell>
                                    < Dropdown
                                        label="Action"
                                        inline={true}
                                        placement="bottom-end"
                                    >
                                        <Dropdown.Item icon={HiArchiveBoxXMark} onClick={() => { handleDeleteZones(zone.uuid) }}>
                                            Delete
                                        </Dropdown.Item>
                                        <Dropdown.Item icon={AiFillSetting} onClick={() => { handleGeneratePassword(zone.uuid) }}>
                                            Generate Password
                                        </Dropdown.Item>
                                    </Dropdown>
                                </Table.Cell>
                            </Table.Row>
                        )
                    }) : "No zones data available"}
                </Table.Body>
            </Table>
        </>
    )
}

export default ZonesTable

// isActive