import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi2";

interface IModalAlert {
  show: boolean;
  setShow: any;
  title?: string;
  statement?: string;
  onSuccess: any;
  id?: any;
}

export default function ModalAlert(props: IModalAlert) {
  return (
    <>
      <Modal
        show={props.show}
        size="md"
        popup={true}
        onClose={() => props.setShow(false)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {props.title}
            </h3>
            <p className="mb-5 text-sm text-gray-800 dark:text-gray-400">
              {props.statement}
            </p>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={props.onSuccess}>
                Yes
              </Button>
              <Button color="gray" onClick={() => props.setShow(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
