import { Card } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import EditNormalizationModal from '../../../components/Modals/EditNormalization';
import NormalizationShiftTable from '../../../components/Tables/NormalizationShift.table'
import ExamService from '../../../services/private/Exam/exam.service'

const NormalizationShift = () => {
    const { examId } = useParams();
    
    const [shiftData, setShiftData] = useState<any>();

    const getExamData = async () => {
        await ExamService.getExamShifts(examId)
            .then((res: any) => {
                if (res.status === 200) {
                    setShiftData(res?.data);
                }
            })
            .catch((err) => {
                toast.error("Unable to get shifts!!");
            });
    };

  

    // const genRand = (min: number, max: number, decimalPlaces: number) => {
    //     var rand = Math.random() * (max - min) + min;
    //     var power = Math.pow(10, decimalPlaces);
    //     return Math.floor(rand * power) / power;
    // }


    let randomNumber = Array.from({ length: 6 }, () => Math.floor(Math.random() * 39) / Math.pow(10, 2));
    console.log(randomNumber, "random");

    useEffect(() => {
        getExamData();
    }, [])

    return (
      <>
        <div className="my-10 mx-5">
          <Card>
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Normalization Shifts
            </h5>
            <NormalizationShiftTable
              tableData={shiftData}
              randomData={randomNumber}
              reload={()=>getExamData()}
            />
          </Card>
        </div>
      </>
    );
}

export default NormalizationShift