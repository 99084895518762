import React from "react";
import IRouter from "../../../interfaces/IRouter";
import { EditExamDropDownRouter } from "../../../Router";
import ExamService from "../../../services/private/Exam/exam.service";
import Clock from "./Clock";


const ExamClocks = () => {
  return (
    <div className="ml-10 mt-10">
      <div className="flex flex-wrap gap-4 items-center mb-4">
        <Clock
          heading={"Biometric Start Time"}
          isClicked={true}
          name={"Biometric Start Time"}
          inputName={"biometricstarttime"}
        />
        <Clock
          heading={"Candidate Data time"}
          isClicked={true}
          name={"Candidate Data time"}
          inputName={"candidatedatetime"}
        />
        <Clock
          heading={"Candidate Start time"}
          isClicked={true}
          name={"Candidate Start time"}
          inputName={"candidatestarttime"}
        />

        <Clock
          heading={"ExamSte Download Time"}
          isClicked={true}
          name={"ExamSte Download Time"}
          inputName={"examstedownloadtime"}
        />
        <Clock
          heading={"Gate closing time"}
          isClicked={true}
          name={"Gate closing time"}
          inputName={"gateclosingtime"}
        />
        <Clock
          heading={"Live Exam start Time"}
          isClicked={true}
          name={"Live Exam start Time"}
          inputName={"liveexamstarttime"}
        />
        <Clock
          heading={"Login Time"}
          isClicked={true}
          name={"Login Time"}
          inputName={"logintime"}
        />
        <Clock
          heading={"Minimum Submission Time"}
          isClicked={true}
          name={"Minimum Submission Time"}
          inputName={"minimumsubmissiontime"}
        />
        <Clock
          heading={"QP delivery time"}
          isClicked={true}
          name={"QP delivery time"}
          inputName={"qpdeliverytime"}
        />

        <Clock
          heading={"User data delivery time"}
          isClicked={true}
          name={"User data delivery time"}
          inputName={"userdatadeliverytime"}
        />

        <Clock
          heading={"Exam Sync Time"}
          isDataSync={true}
          name={"Exam Sync Time"}
          inputName={"synctime"}
        />
        <Clock
          heading={"Range"}
          isRange={true}
          name={"Range"}
          isExamEndTime={true}
          isExamStartTime={true}
          inputExamStart={"shiftstartdateandtime"}
          inputExamEnd={"shiftenddateandtime"}
        />
        <Clock
          heading={"Exam Start & End Time"}
          isExamTime={true}
          name={"Exam Start & End Time"}
          isExamEndTime={true}
          isExamStartTime={true}
          inputExamStart={"examstarttime"}
          inputExamEnd={"examendtime"}
        />
      </div>
    </div>
  );
};

export default ExamClocks;
