import { CgPassword } from "react-icons/cg";
import { HiBookOpen, HiBuildingOffice } from "react-icons/hi2";
import { MdPassword } from "react-icons/md";
import IRouter from "../interfaces/IRouter";
import Center from "../views/Exam/Centers/Center";
import ExmwallPassword from "../views/Exam/Centers/ExmwallPassword";

const centersRouter: IRouter[] = [
  {
    path: "center",
    element: <Center />,
    navbarShow: true,
    name: "Center",
    icon: HiBuildingOffice,
  },
  {
    path: "exmwallpassword",
    element: <ExmwallPassword />,
    navbarShow: true,
    name: "ExamWall-Password",
    icon: CgPassword,
  },
];

export default centersRouter