import { Table } from "flowbite-react";

import { useParams } from "react-router";

interface IShiftTable {
  tableData: any;
  reload: any;
}

const FinalAnswerSheetTable = ({ tableData, reload }: IShiftTable) => {

  const params: any = useParams();
 



  return (
    <div>
      <Table>
        <Table.Head>
          <Table.HeadCell>Sr. No.</Table.HeadCell>
          <Table.HeadCell>Username</Table.HeadCell>
          <Table.HeadCell>Registration Number</Table.HeadCell>
          <Table.HeadCell>Final Result</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {tableData && tableData.length > 0
            ? tableData.map((data: any, index: number) => {
                return (
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{data ? data.username : "--"}</Table.Cell>
                    <Table.Cell>{data ? data.registrationNumber : "--"}</Table.Cell>
                    <Table.Cell>{data ? data.result : "--"}</Table.Cell>
                  </Table.Row>
                );
              })
            : "No data available"}
        </Table.Body>
      </Table>
    </div>
  );
};

export default FinalAnswerSheetTable;
