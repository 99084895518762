import { Card} from "flowbite-react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router";
import ExamService from "../../../services/private/Exam/exam.service";
import Select from "react-select";

const AnswerKeyUpload = () => {
  const { examId } = useParams();

  const [shiftData, setShiftData] = useState<any>();
  const [shiftId, setShiftId] = useState<any>();

  console.log(shiftId);
  const handleSelectValue = (e: any, name: string) => {
    setShiftId({ ...shiftId, [name]: e.value });
  };

  const handleUploadedFile = async (files: any) => {
    let formData = new FormData();
    formData.append("file", files.target.files[0]);

    toast.promise(
      ExamService.uploadAnswerKey(examId, shiftId.id, formData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Upload Complete");
          }
        })
        .catch((err) => {
          toast.error("Something went wrong");
        }),
      {
        loading: "Uploading...",
        success: "Upload Complete",
        error: "Something went wrong",
      }
    );

  };
  const getAllShift = async () => {
    await ExamService.getExamShifts(examId).then((res: any) => {
      if (res.status === 200) {
        setShiftData(
          res.data.map((data: any) => {
            return {
              value: data.shiftid,
              label: data.shiftname,
            };
          })
        );
      }
    }).catch((err)=>{
    toast.error("Something went wrong");
    });
  };

  useEffect(() => {
    getAllShift();
  }, []);

  return (
    <div className="m-3">
      <div className="mt-5">
        <Card className="w-full">
          <h3 className="m-2 text-2sm font-bold tracking-tight text-gray-900 dark:text-white">
            Select Shift name
          </h3>
          <Select
            options={shiftData}
            onChange={(e: any) => handleSelectValue(e, "id")}
          />
        </Card>
      </div>
      <div className="mt-2">
        <div className="flex items-center justify-center w-full">
          <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                aria-hidden="true"
                className="w-10 h-10 mb-3 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                ></path>
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">.zip </p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={handleUploadedFile}
            />
          </label>
        </div>
      </div>
    </div>
  );
};
export default AnswerKeyUpload;
