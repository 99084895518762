import IRouter from "../interfaces/IRouter";
import Exams from "../views/Admin/Exams/Exams";

import { HiBookOpen } from "react-icons/hi2";
import { AiFillDashboard } from "react-icons/ai";
import Dashboard from "../views/Admin/Dashboard/Dashboard";
import dashboardNavigator from "../navigator/admin/dashboard.navigator";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { FiMonitor } from "react-icons/fi";
import LiveExams from "../views/Admin/Exams/LiveExam";
import Monitoring from "../views/Admin/Monitoring/Monitoring";

export const baseurl = "/admin/";

const adminRoutes: IRouter[] = [
  {
    path: "dashboard",
    element: <Dashboard />,
    navbarShow: true,
    name: "admin",
    icon: AiFillDashboard,
    navigator: dashboardNavigator,
  },
  {
    path: "exams",
    element: <Exams />,
    navbarShow: true,
    name: "exam",
    icon: HiBookOpen,
  },
  {
    path: "live-exams",
    element: <LiveExams />,
    navbarShow: true,
    name: "Live Exams",
    icon: HiOutlineVideoCamera,
  },
  {
    path: "monitoring",
    element: <Monitoring />,
    navbarShow: true,
    name: "Monitoring",
    icon: FiMonitor,
  },

  // {
  //   path: "exam/:examId",
  //   element: <ExamIndex render={undefined} />,
  //   navbarShow: false,
  //   name: "exam",
  //   icon: HiBookOpen
  // }
];

export default adminRoutes;
