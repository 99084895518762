import LiveExamSidebar from "../../components/Sidebar/LiveExam.sidebar";

interface IExamIndex {
  render: any;
}

export default function LiveExamIndex({ render }: IExamIndex) {
  return (
    <>
      <LiveExamSidebar render={render} />
    </>
  );
}
