import { HiMegaphone } from "react-icons/hi2";
import IRouter from "../interfaces/IRouter";
import FinalAnswerSheet from "../views/Exam/FinalAnswerSheet/FinalAnswerSheet";

const finalAnswerSheetRouter: IRouter[] = [
  {
    path: "finalAnswerSheet",
    element: <FinalAnswerSheet />,
    navbarShow: true,
    name: "Final-Answer-Sheet",
    icon: HiMegaphone,
  },
];

export default finalAnswerSheetRouter;
