import { HiUser } from "react-icons/hi";
import { HiShieldExclamation } from "react-icons/hi2";
import IRouter from "../interfaces/IRouter";
import LiveUsersIndex from "../views/Live/LiveUsers/LiveUsers.index";
import ResponsesIndex from "../views/Live/Responses/Responses.index";

const liveUserRouter: IRouter[] = [
    {
      path: "liveUsers",
      element: <LiveUsersIndex />,
      navbarShow: true,
      name: "Live Users",
      icon: HiUser,
    },
    {
      path: "responses",
      element: <ResponsesIndex />,
      navbarShow: true,
      name: "Responses",
      icon: HiShieldExclamation,
    },
];

export default liveUserRouter