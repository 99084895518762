import { Card } from 'flowbite-react';
import React from 'react'
import StatsCard from '../../../components/Cards/Stats.card';
import { GiAlarmClock } from "react-icons/gi";
import { RiBaseStationLine } from "react-icons/ri";
import { RiWifiOffLine } from "react-icons/ri";
import { VscDebugDisconnect } from "react-icons/vsc";
import { CgMediaLive } from "react-icons/cg";
import { RxAvatar } from "react-icons/rx";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { MdOutlinePendingActions } from "react-icons/md";







const ExamStatsIndex = () => {
  return (
    <div className="ml-10 mt-10">
      <div className='flex flex-wrap justify-between items-center'>
        <StatsCard
          text="Online"
          count="20"
          color="border-green-600"
          icon={<RiBaseStationLine className="text-xl" />}
        />
        <StatsCard
          text="Offline"
          count="20"
          color="border-green-600"
          icon={<RiWifiOffLine className="text-xl" />}

        />
        <StatsCard
          text="Disconnected"
          count="20"
          color="border-red-600"
          icon={<VscDebugDisconnect className="text-xl" />}

        />
        <StatsCard
          text="Biometric"
          count="20"
          color="border-green-600"
          icon={<RiBaseStationLine className="text-xl" />}

        />
        <StatsCard
          text="Ongoing Exam"
          count="20"
          color="border-green-600"
          icon={<CgMediaLive className="text-xl" />}

        />
        <StatsCard
          text="Total Student"
          count="20"
          color="border-green-600"
          icon={<RxAvatar className="text-xl" />}

        />
        <StatsCard
          text="QP Download"
          count="20"
          color="border-green-600"
          icon={<AiOutlineCloudDownload className="text-xl" />}

        />
        <StatsCard
          text="QP Pending"
          count="20"
          color="border-green-600"
          icon={<MdOutlinePendingActions className="text-xl" />}

        />
      </div>
    </div>
  );
}

export default ExamStatsIndex

