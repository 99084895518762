import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import MainRouter from './Router';
import { Toaster } from 'react-hot-toast';
import "react-datetime/css/react-datetime.css";



function App() {
  return (
    <div>
      <BrowserRouter>
        <MainRouter />
      </BrowserRouter>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          className: "flex items-center p-4 max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 p-5 text-sm font-normal",
        }}
      />
    </div>
  );
}

export default App;
