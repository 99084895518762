
export const baseurl = "/admin/dashboard";

const dashboardNavigator = [
    {
        baseurl: baseurl,
        route: "stats",
        name: "Stats"
    },
]

export default dashboardNavigator;