import { Button, Checkbox, Label, Modal, TextInput } from 'flowbite-react'
import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import { toast } from 'react-hot-toast';
import AdminService from '../../services/private/Admin/admin.service';

interface ICreateExamModal {
    show: boolean;
    setShow: any;
}

const CreateExamModal = (props: ICreateExamModal) => {


    let [examName, setExamName] = useState<string>('');

    const createExam = async () => {
        await AdminService.createExam({ examName }).then((res) => {
            if(res.status === 200){
                setExamName("")
                props.setShow(false)
                toast.success("Exam Created")
            }
        })
    }

    const onClose = () => {
        props.setShow(false);
    }

    return (
        <React.Fragment>
            <Modal
                show={props.show}
                onClose={onClose}
            >
                <Modal.Header>
                    Create Exam
                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="examName"
                                    value="Exam Name"
                                />
                            </div>
                            <TextInput
                                id="examName"
                                placeholder="Exam Name here"
                                required={true}
                                onChange={(e: any) => setExamName(e.target.value)}
                            />
                        </div>



                        <div className="w-full mt-3">
                            <Button
                                onClick={createExam}
                            >
                                Create Exam
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default CreateExamModal