import { Button, Card, Label } from "flowbite-react";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useParams } from "react-router";
import ExamService from "../../../services/private/Exam/exam.service";
import MonitoringServices from "../../../services/private/Exam/monitoring.services";
import { toast } from "react-hot-toast";
import ZonesTable from "../../../components/Tables/zones.table";
import CandidateDevicesTable from "../../../components/Tables/CadidateDevices.table";
import CandidateTimingsTable from "../../../components/Tables/CandidateTimings.table";

export default function CandidateTimings() {
  const { examId } = useParams();

  const [centers, setCenters] = useState<any>();
  const [selectedNames, setSelectedNames] = useState<any>([]);
  const [shifts, setShifts] = useState<any>();
  const [zoneData, setZoneData] = useState<any>();
  const [data, setData] = useState<any>();
  const [shiftId, setShiftId] = useState<any>();

  const handleChange = (event: any) => {
    setSelectedNames(event);
  };

  const handleValueChange = (e: any) => {
    setZoneData({ ...zoneData, [e.target.name]: e.target.value });
  };

  const handleShiftValue = (e: any, name: any) => {
    setShiftId({ ...shiftId, [name]: e.value });
  };

  const getAllShifts = async () => {
    await ExamService.getExamShifts(examId).then((res) => {
      if (res.status === 200) {
        setShifts(
          res.data.map((data: any) => {
            return {
              value: data.shiftid,
              label: data.shiftname,
            };
          })
        );
      }
    });
  };

  const handleSearch = async () => {
    await MonitoringServices.getCandidateTimings(examId, shiftId.shiftId)
      .then((res: any) => {
        if (res.status === 200) {
          setData(res.data);
        } else setData([]);
      })
      .catch((err) => {
        toast.error("Unable to create zone");
        setData([]);
      });
  };

  useEffect(() => {
    getAllShifts();
  }, []);

  return (
    <div>
      <div className="my-10 mx-5 w-full">
        <Card>
          <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            Candidate Timings
          </h5>
          <div className="mt-1">
            <Label className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Select Shift
            </Label>
            <Select
              onChange={(e: any) => {
                handleShiftValue(e, "shiftId");
              }}
              options={shifts}
              isClearable={true}
              hideSelectedOptions={true}
              placeholder="Select Shift"
              className=""
            />
          </div>

          <div className="flex flex-col items-end mt-3">
            <Button size="sm" onClick={handleSearch}>
              Search
            </Button>
          </div>
        </Card>
      </div>
      <div className="my-10 mx-5 w-full">
        <Card>
          <CandidateTimingsTable tableData={data} reload={handleSearch} />
        </Card>
      </div>
    </div>
  );
}
