import React from 'react'
import ExamBuffer from './Buffer'

const BufferIndex = () => {
    return (
        <>
            <ExamBuffer />
        </>
    )
}

export default BufferIndex