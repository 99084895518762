import IRouter from "../../../interfaces/IRouter";
import { EditLiveExamDropDownRouter, FinalAnswerSheetRouter } from "../../../Router";
import FinalAnswerSheet from "./FinalAnswerSheet";

interface ICentersIndex {
  children: IRouter[];
}

const FinalAnswerSheetIndex = (props: ICentersIndex) => {
  return (
    <>
    <FinalAnswerSheet/>
    </>
  );
};

export default FinalAnswerSheetIndex;
