import IRouter from "../../../interfaces/IRouter"
import { EditExamDropDownRouter } from '../../../Router';

interface ILiveExamSettingsIndex {
    children: IRouter[]
}

export default function LiveExamSettingsIndex(props: ILiveExamSettingsIndex) {

  
    return (
        <>
            <EditExamDropDownRouter children={props.children}/>
        </>
    )
}