import IRouter from "../interfaces/IRouter";
import { HiBookOpen } from "react-icons/hi2";
import ExamStatsIndex from "../views/Live/ExamStats/ExamStats.index";
import CandidateDevices from "../views/Monitoring/CandidateDevices/CandidateDevices.index";
import CandidateTimings from "../views/Monitoring/CandidateTImings/CandidateTimings.index";
import Suspects from "../views/Monitoring/Suspects/Suspects.index";

const monitoringsuspectsrouter: IRouter[] = [
  {
    path: "",
    element: <Suspects />,
    navbarShow: true,
    name: "Suspects",
    icon: HiBookOpen,
  },
];

export default monitoringsuspectsrouter;
