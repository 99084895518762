import { table } from 'console'
import { Card, Table, TextInput } from 'flowbite-react'
import React, { useState } from 'react'
import Shift from '../../views/Exam/Shifts/Shift'
import EditBufferModal from '../Modals/EditBuffer.modal'

interface IBufferTable {
    tableData: any,
    refresh: any
}

const BufferTable = ({ tableData, refresh }: IBufferTable) => {
    const [bufferIndex, setBufferIndex] = useState<number>(-1)

    return (
        <>
            <div>
                <Table hoverable={true}>
                    <Table.Head>
                        <Table.HeadCell>
                            Sr. No.
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Category
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Buffer {"(in minutes)"}
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Action
                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {tableData && tableData.length > 0 ? tableData.map((data: any, index: number) => {
                            return (
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={index}>
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        {index + 1}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {data ? data.category : "--"}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {data ? data.timeBuffer : "--"}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <span
                                            className="font-medium text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
                                            onClick={() => { setBufferIndex(index) }}
                                        >
                                            Edit
                                        </span>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }) : "No buffer data found"}

                    </Table.Body>
                </Table>
            </div>
            <EditBufferModal
                show={bufferIndex >=0 ? true : false}
                onClose={() => {
                    setBufferIndex(-1)
                    refresh()
                }}
                data={tableData && tableData[bufferIndex]}
            />
        </>
    )
}

export default BufferTable