
import React, { useEffect, useState } from "react";
import { Button, Card, Label } from "flowbite-react";
import { useParams } from "react-router";
import ExamService from "../../../services/private/Exam/exam.service";
import Select from "react-select";

import LiveCenterTable from "../../../components/Tables/LiveCenterTable";
import StudentActvieCard from "../../../components/Cards/Student.active.card";
import StudentBlockedCard from "../../../components/Cards/Student.blocked.card";
import StudentAllCard from "../../../components/Cards/Student.all.card";
import LiveService from "../../../services/private/Exam/live.service";
import { toast } from "react-hot-toast";

const LiveCentersIndex = () => {
  const { examId }: any = useParams();

  const [status, setStatus] = useState<any>({
    label: "Online",
    value: "online",
  });
  const [centers, setCenters] = useState<any>();
  const [shifts, setShifts] = useState<any>();
  const [centerData, setCenterData] = useState<any>([]);
  const [lastSyncedSort, setLastSyncedSort] = useState<any>({
    label: "Ascending",
    value: "ascending",
  });
  const [count, setCount] = useState<any>(0);
  // const [lastLogin, setLastLogin] = useState<any>({
  //   label: "Ascending",
  //   value: -1,
  // });


  const handleData = async () => {
    await LiveService.getCenterData(
      examId,
      status?.value,
      shifts?.value,
      centers?.value,
      lastSyncedSort?.value
    )
      .then((res) => {
        if (res.status === 200) {
          setCenterData(res.data.onlineUsers);
        }
      })
      .catch((err) => {
        toast.error("Fetching Center Data Failed");
      });
  };



  const getCenterCount = async () => {
    await LiveService.getCenterCount(examId).then((res) => {
      if (res.status === 200) {
        setCount(res?.data);
      }
    }).catch((err) => {
      toast.error('Fetching Center Count Failed')
    });
  };



  const fetchCenterData = () => {
    ExamService.getLiveExamCenters(examId)
      .then((res) => {
        if (res.status === 200) {
          setCenters(
            res?.data?.map((data: any, index: any) => {
              return {
                value: data,
                label: data,
              };
            })
          );
        }
      })
      .catch((err) => console.log(err));
  };
  const getAllShiftsByExamId = async () => {
    ExamService.getExamShifts(examId)
      .then((res: any) => {
        if (res.status === 200) {
          console.log(res.data, "shift data")
          setShifts(
            res?.data?.map((data: any) => {
              return {
                value: data?.shiftid,
                label: `${data?.shiftname}`,
              };
            })
          );
        }
      })
      .catch((err) => {
        toast.error("Fetching Shifts Failed");
      });
  };

  //  const getAllZones = async () => {
  //    await ExamService.getAllZones(examId).then((res: any) => {
  //      if (res.status === 200) {
  //        setZone(res.data);
  //      }
  //    });
  //  };


  useEffect(() => {
    getCenterCount();
    fetchCenterData();
    getAllShiftsByExamId();
    //  getAllZones();
  }, []);




  return (
    <>
      <div className="my-10 mx-5">
        <div className="flex justify-between items-center">
          <StudentActvieCard
            text="Online"
            count={count?.onlineUsers?.count || 0}
            type="active"
          />
          <StudentAllCard
            text="Offline"
            count={count?.offlineUsers?.count || 0}
          />
          <StudentBlockedCard
            text="Disconnected"
            count={count?.disconnectedUsers?.count || 0}
            type="blocked"
          />
          <StudentActvieCard text="Biometric" count="1" type="active" />
          <StudentBlockedCard text="Blocked" count="--" type="blocked" />
        </div>
        <div className="my-5">
          <Card>
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              Filter By
            </h5>
            <div className="flex">
              <div className="flex-initial mr-8">
                <Label className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  Shift
                </Label>
                <Select
                  options={shifts}
                  onChange={setShifts}
                  placeholder="select the Shift..."
                />
              </div>

              <div className="flex-initial mr-8">
                <Label className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  Center Id
                </Label>
                <Select
                  onChange={setCenters}
                  placeholder="select the center..."
                  options={centers}
                />
              </div>

              <div className="flex-initial mr-6">
                {" "}
                <Label className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  Status
                </Label>
                <Select
                  options={[
                    { label: "Online", value: "online" },
                    { label: "Offline", value: "offline" },
                    { label: "Disconnected", value: "disconnected" },
                  ]}
                  value={status}
                  onChange={setStatus}
                  placeholder="Select the status..."
                />
              </div>
              <div className="flex-initial mr-6">
                <Label className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  Last Synced At
                </Label>
                <Select
                  options={[
                    { label: "Ascending", value: "ascending" },
                    { label: "Descending", value: "descending" },
                  ]}
                  value={lastSyncedSort}
                  placeholder="Select the order..."
                  onChange={setLastSyncedSort}
                />
              </div>
            </div>

            <div className="flex flex-col items-end mt-8">
              <Button onClick={() => handleData()}>Get</Button>
            </div>
          </Card>
        </div>
        <Card>
          <LiveCenterTable tableData={centerData} />
        </Card>
      </div>
    </>
  );
};

export default LiveCentersIndex;