import { Button, Table } from "flowbite-react";
import React, { useState } from "react";
import CenterDataModal from "../Modals/CenterData.modal";
import CenterDetailModal from "../Modals/CenterDetail.modal";

interface ICenterStudents {
  tableData: any;
  showLab: boolean;
  setShowLab: any;
}

const LabDetailTable = ({
  tableData,
  showLab,
  setShowLab,
}: ICenterStudents) => {

  return (
    <div>
      <Table hoverable={true}>
        <Table.Head>
          <Table.HeadCell>Sr. No.</Table.HeadCell>
          <Table.HeadCell>Center Id</Table.HeadCell>
          <Table.HeadCell>No. of Students</Table.HeadCell>
          <Table.HeadCell>Action</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {tableData && tableData.length > 0
            ? tableData.map((data: any, index: number) => {
                return (
                  <Table.Row>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>
                      <u onClick={() => setShowLab(true)}>
                        {data ? data.center : "--"}
                      </u>
                    </Table.Cell>
                    <Table.Cell>{data ? data.candidates : 0}</Table.Cell>
                    <Table.Cell>
                      <span
                        className="text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
                        onClick={() => {
                          setShowLab(true);
                        }}
                      >
                        View
                      </span>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            : "No data found"}
        </Table.Body>
      </Table>
    </div>
  );
};

export default LabDetailTable;
