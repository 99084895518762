import React, { useEffect, useState } from "react";
import { Button, Card, Label, Spinner } from "flowbite-react";
import { useParams } from "react-router";
import ExamService from "../../../services/private/Exam/exam.service";
import toast from "react-hot-toast";
import ResponsesTable from "../../../components/Tables/Responses.table";
import LiveService from "../../../services/private/Exam/live.service";
import Select from "react-select";
import ReservationService from "../../../services/private/Exam/reservation.service";


const Responses = () => {

  const { examId }: any = useParams();
  const [responses, setResponses] = React.useState<any>([]);
  const [shift, setShift] = useState<any>();


  const getAllExamCenter = async () => {
    await ReservationService.getAllCenters().then((res) => {
      if (res.status === 200) {
        console.log(res.data, "center");
      }
    })
  }

  const getAllExamShifts = async () => {
    await ExamService.getExamShifts(examId).then((res) => {
      if (res.status === 200) {
        setShift(res.data.map((shift: any) => {
          return {
            value: shift._id,
            label: shift.shiftname
          }
        }))
      }
    })
  }


  const getResponses = async () => {
    await LiveService.getResponses(examId).then(res => {
      if (res.status === 200) {
        setResponses(res.data);
      }
    })
  }

  React.useEffect(() => {
    getResponses();
    getAllExamCenter();
    getAllExamShifts();
  }, [])

  return (
    <>
      <div className="ml-10 mt-10">
        <Card className="mb-5">
          <h5 className="text-l font-bold tracking-tight text-gray-900 dark:text-white">
            Filter By
          </h5>

          <div className="flex justify-between items-center">
            <div className="w-full mr-3">
              <Label className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                Shift
              </Label>
              <Select
                options={shift}
                onChange={setShift}
              />
            </div>
            <div className="w-full mr-3">
              <Label className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                Center
              </Label>
              <Select
                options={shift}
                onChange={setShift}
              />
            </div>
            <div className="w-full mr-3">
              <Label className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                last Synced At
              </Label>
              <Select
                options={[
                  { label: "Ascending", value: 1 },
                  { label: "Descending", value: -1 },
                ]}
                onChange={() => { }}
              />
            </div>
          </div>
        </Card>
        <Card>
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Live Responses Sync
              </h5>

            </div>
            <ResponsesTable
              tableData={responses}
            />{" "}
          </>
        </Card>
      </div>


    </>
  )
}

export default Responses;
