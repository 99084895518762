import { HiOutlineBars3CenterLeft } from "react-icons/hi2";
import IRouter from "../interfaces/IRouter";
import LiveCentersIndex from "../views/Live/LiveCenters/LiveCenters.index";

const liveCentersRouter: IRouter[] = [
    {
      path: "liveCenter",
      element: <LiveCentersIndex />,
      navbarShow: true,
      name: "Live Centers",
      icon: HiOutlineBars3CenterLeft,
    },
];

export default liveCentersRouter