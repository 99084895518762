import { Badge, Table } from "flowbite-react";
import React from "react";
import toast from "react-hot-toast";
import { HiArchiveBoxXMark, HiCheckCircle, HiXCircle } from "react-icons/hi2";
import ExamService from "../../services/private/Exam/exam.service";

interface ISuspectsTable {
  tableData: any;
  reload: any;
}

const SuspectsTable = ({ tableData, reload }: ISuspectsTable) => {
  return (
    <>
      <Table hoverable={true}>
        <Table.Head>
          <Table.HeadCell>S.No.</Table.HeadCell>
          <Table.HeadCell>Student Id</Table.HeadCell>
          <Table.HeadCell>Reason</Table.HeadCell>
          <Table.HeadCell>Center Id</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {tableData && tableData.length > 0
            ? tableData.map((suspect: any, index: any) => {
                return (
                  <Table.Row
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    key={index}
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {index + 1}
                    </Table.Cell>
                    <Table.Cell>{suspect ? suspect.studentId : "--"}</Table.Cell>
                    <Table.Cell>{suspect ? suspect.reason : "--"}</Table.Cell>
                    <Table.Cell>{suspect ? suspect.centerId : "--"}</Table.Cell>
                  </Table.Row>
                );
              })
            : "No suspect data available"}
        </Table.Body>
      </Table>
    </>
  );
};

export default SuspectsTable;

// isActive
