import admin_urls from "./private/Admin/admin.urls";

const urls = {
  public: {
    auth: {
      login: "/auth/login",
    },
  },
  protected: {
    admin: admin_urls,
    section: {
      delete: "/exam/deleteSection",
    },
    live: {
      reponses: "/live-exam/responses",
      online: "/exmste-client/onlineSteUsers",
      centerCount: "/live-exam/statsData",
      clockTimings: "/verifyLiveExam/clockTimings/",
      questionPaper: "/verifyLiveExam/section",
      shifts: "/verifyLiveExam/shift",
      candidatesForCenter: "/verifyLiveExam/candidatesForCenter",
      timeBuffer: "/verifyLiveExam/timeBuffer",
      qpBankHooks: "/verifyLiveExam/qpBankHooks",
      bioUsers: "/verifyLiveExam/bioUsers",
      exmsteData: "/verifyLiveExam/exmsteData",
      zone: "/verifyLiveExam/zone",
      section: "/verifyLiveExam/section",
      asymmetricKeys: "/verifyLiveExam/asymmetricKeys",
    },
    exam: {
      getExamShifts: "/exam/getExamShifts",
      getExamById: "/exam/get",
      addShifts: "/exam/addshift",
      deleteShift: "/exam/deleteshift",
      getTimeBuffer: "/exam/getTimeBuffersByExamUUID",
      addTimeBuffer: "/exam/insertTimeBuffer",
      generateClientAccount: "/exam/createClientUser",
      getExamSections: "/exam/getSections",
      addSection: "/exam/addSection",
      updateTimeBuffer: "/exam/updateTimeBuffer",
      checkIfQuestionPaperExistForExam:
        "/exmbnk/checkIfQuestionPaperExistForExam",
      getAllCenterByExamId: "/exmcen/getCenterByExamId",
      getAllStudents: "/studentRegisteration/getAllStudents",
      updateHooksData: "/hooks/updateExamUrlsByUUID",
      getHooksData: "/hooks/getAllUrls",
      updateShift: "/exam/updateShift",
      updateShiftTime: "/exam/updateShiftTime",
      candidatesPerCenter: "/exam/candidatesPerCenter",
      getExmsteServerAndBioDevices: "/exam/getExmsteServerAndBioDevices",
      editBioDevicesAndExmsteCount: "/exam/editBioDevicesAndExmsteCount",
      verifyLiveExamClockTiming: "/verifyLiveExam/clockTimings",
      verifyLiveExamShift: "/verifyLiveExam/shift",
      verifyLiveExamQuestionPaper: "/verifyLiveExam/questionPaper",
      getCenters: "/exmcen/getAllCenters",
      toggleExamShiftLiveStatus: "/exam/toggleExamShiftLiveStatus",
      uploadToS3: "upload/uploadToS3",
      updateExam: "/exam/update-exam",
      getLiveExamCenters: "/live-exam/centers",
      createPassword: "/examste/create/stePassword",
      toggleMockExam: "/exam/toggleMock"
    },
    monitoring: {
      getCandidateDevices: "/live-exam/candidateDevices",
      getCandidateTimings: "/live-exam/candidateTimings",
      getLabDevices: "/live-exam/labDevices",
      getSuspects: "/live-exam/suspects",
      getLastSyncLog: "/live-exam/lastSyncLog",
      getExtraTimings: "/live-exam/extraTimings"
    },
    reservation: {
      getAllCenters: "/exmcen/getAllCenters",
      getCenterById: "/exmcen/getCenterById",
      getCentersArrCapacity: "/exmcen/getCentersArrCapacity",
      getCenterArrDetails: "/exmcen/getCenterArrDetails",
      getCenterBookingLockStatus: "/bookings/getCenterBookingLockStatus",
      getExamBookingLockStatus: "/bookings/getExamBookingLockStatus",
      lockExamBooking: "/bookings/lockExamBooking",
      createCenterBooking: "/bookings/exmcenExamBookings",
      deleteCenterBooking: "/bookings/removeLockForCenterBooking",
      unLockExamBooking: "/bookings/unLockExamBooking",
      getTempCenters: "/bookings/getTempSavedCenters",
      lockTempCenters: "/bookings/lockTempSavedCenters",
      saveTempCenters: "/bookings/tempsavedCenters",
      unlockTempCenters: "/bookings/unlockTempSavedCenters",
      checkReservationInit: "/bookings/checkReservationInit",
      getCentersForBookingWithLabel: "/bookings/getCentersForBookingWithLabel",
      getChoicesDist: "/bookings/getSubmitedUserDistrict",
      getStudentsinDistrictWithChoice:
        "/bookings/getStudentsinDistrictWithChoice",
      getStudentReservationByCenterIdandShiftId:
        "/bookings/getStudentReservationByCenterIdandShiftId",
      getStudentReservationStats: "/bookings/getReservationStudentStats",
      allotCenters: "/bookings/allotCenters",
      clearBookingForCenterinShift: "/bookings/clearBookingForCenterinShift",
      clearBookingForStudentsInExam: "/bookings/clearBookingForStudentsInExam",
      getCentersLabData: "",
      getLabById: "",
    },
    students: {
      getStudentsFromEXMREG: "/studentRegisteration/getAllStudents",
      uploadUserDataZip: "/studentRegisteration/uploadUserDataZip",
      uploadAnswerKey: "/answer/create",
      uploadUserCsvData: "/studentRegisteration/uploadUserCsv"
    },

    questions: {
      addQuestionToShift: "/question-paper/addPaperToShift",
    },

    Exmste: {
      createExamSTEUsers: "/examste/createExamSTEUsers",
      checkExamSteUser: "/examste/checkExmsteUsersExist",
      initialExmsteUser: "/examste/initialExmsteUser",
      removeExamSteUser: "/examste/removeExmsteUsers",
    },

    zone: {
      getAllZonesByExamId: "/zone/getByExam",
      createZones: "/zone/createZone",
      deleteZone: "/zone/delete",
      generatePassword: "/zone/zoneUser"
    },

    Exmcen: {
      labAllotment: "/exmcen/labAllotment",
    },
  },
};

export default urls;
