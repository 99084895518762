import AdminSidebar from "../../components/Sidebar/Admin.sidebar";
import Topbar from "../../components/TopBar/Topbar";


interface IAdminIndex{
    render: any
}

export default function AdminIndex(props: IAdminIndex) {
    return (
        <>
            <Topbar />
            <AdminSidebar render={props.render} />
        </>
    )
}
