const admin_urls = {
  dashboard: {},
  exams: {
    allExams: "/exam/get",
    createExam: "/exam/createExam",
    allLiveExam: "/exam/getLiveExams",
    updateLiveExam: "/exam/update",
    getAllLiveExams: "/exam/getLiveExams"
  },
};

export default admin_urls;
