import { Button, Card } from 'flowbite-react';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import CentersTable from '../../../components/Tables/Centers.table'
import ExamService from '../../../services/private/Exam/exam.service';
import Select from "react-select"
import toast from 'react-hot-toast';
import ModalAlert from '../../../components/Modals/Modal.alert';

const Center = () => {
  const { examId }: any = useParams();

  const [centersData, setCentersData] = useState<any>();
  const [shiftData, setShiftData] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [userExists, setUserExists] = useState<boolean>(false);
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const [selectedShift, setSelectedShift] = useState<any>({});




  const checkExamSteUser = async () => {
    await ExamService.checkExamSteUser(examId, selectedShift.value).then((res: any) => {
      if (res.status === 200) {
        if (shiftData) {
          setUserExists(true)
        }
      }
    }).catch(err => {
      setUserExists(false)
    })
  };

  const getAllShift = async () => {
    await ExamService.getExamShifts(examId).then((res: any) => {
      if (res.status === 200) {
        setShiftData(res.data.map((data: any) => {
          return {
            value: data.shiftid,
            label: data.shiftname
          }
        }))
      }
    })
  }


  const handleCenterChange = async (

  ) => {

    const payload = centersData.map((data: any) => {
      return {
        centerId: data.centerId,
        serverCount: data.serverCount,
        biometricDevicesPerServer: data.biometricDevicesPerServer
      }
    })

    await ExamService.createExamSTEUsers(
      examId,
      selectedShift.value,
      payload
    )
      .then((res: any) => {
        if (res.status === 200) {
          setShowModal(false);
          toast.success("User created successfully");
          checkExamSteUser();
        }
      })
      .catch((err) => {
        toast.error(err);
      });

  };

  const removeExamSteUser = async () => {
    await ExamService.removeExamSteUser(examId, selectedShift.value).then((res: any) => {
      if (res.status === 200) {
        setShowResetModal(false);
        toast.success("Users removed successfully");
        checkExamSteUser();
        getInitialData();
      }
    }).catch(err => {
      console.log(err)
      toast.error("Failed to remove users");
    })
  }


  const getInitialData = async () => {
    await ExamService.initialExmsteUser(examId, selectedShift.value).then((res: any) => {
      if (res.status === 200) {
        setCentersData(res.data)
      }

    })
  }

  useEffect(() => {
    getAllShift();
  }, [])


  useEffect(() => {
    if (selectedShift.value) {
      getInitialData();
      checkExamSteUser();
    }
  }, [selectedShift])


  return (
    <>
      <div className="my-10 mx-5">
        <div className="my-5">
          <Card>
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              1. Select Shift
            </h5>
            <Select
              options={shiftData}
              onChange={setSelectedShift}
              placeholder="Select the Shift name..."
            />
          </Card>
        </div>
        <div>
          {centersData ? (
            <>
              {userExists === false && (
                <Card>
                  <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    2. Create EXMSTE User
                  </h5>

                  <CentersTable
                    tableData={centersData}
                    updateTable={setCentersData}
                  />

                  <div className="flex flex-col items-end">
                    <Button onClick={() => setShowModal(true)}>Create Center Users</Button>
                  </div>
                </Card>
              )}

              {userExists === true && (
                <Card>
                  Create ExamSte User is locked, users are already created. Do
                  you want to reset?
                  <Button onClick={() => setShowResetModal(true)}>
                    Reset
                  </Button>
                </Card>
              )}
            </>
          ) : (
            <Card>Please select the shift</Card>
          )}
        </div>
      </div>
      <ModalAlert
        show={showModal}
        setShow={() => setShowModal(false)}
        onSuccess={handleCenterChange}
        title={
          "Are you sure you want to create users, this can't be reversed"
        }
      />
      <ModalAlert
        show={showResetModal}
        setShow={() => setShowResetModal(false)}
        onSuccess={removeExamSteUser}
        title={
          "Are you sure you want to reset, this would delete all the users"
        }
      />
    </>
  );
}

export default Center