import IRouter from "../interfaces/IRouter";
import { HiStatusOnline } from "react-icons/hi";
import editliveexamrouter from "./editliveexam.router";
import EditLiveExamIndex from "../views/Live/EditLiveExam/EditLiveExam.index";
import dashboardNavigator from "../navigator/admin/dashboard.navigator";
import liveUserRouter from "./liveUser.router";
import liveCentersRouter from "./liveCenter.router";

export const baseurl = "/liveExam/";

const liveExamRoutes: IRouter[] = [
  {
    path: "examstats",
    element: <EditLiveExamIndex children={editliveexamrouter} />,
    navbarShow: true,
    name: "Exam Stats",
    icon: HiStatusOnline,
    children: editliveexamrouter,
    baseUrl: baseurl,
  },

  {
    path: "liveUsers",
    element: <EditLiveExamIndex children={liveUserRouter} />,
    navbarShow: true,
    name: "Live Users",
    icon: HiStatusOnline,
    children: liveUserRouter,
    baseUrl: baseurl,
  },

  {
    path: "liveCenters",
    element: <EditLiveExamIndex children={liveCentersRouter} />,
    navbarShow: true,
    name: "Live Centers",
    icon: HiStatusOnline,
    children: liveCentersRouter,
    baseUrl: baseurl,
  },
];

export default liveExamRoutes;
