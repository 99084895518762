import React from 'react'
import IRouter from '../../../interfaces/IRouter'
import { EditExamDropDownRouter } from '../../../Router'

interface IClocksIndex {
    children: IRouter[]
}

const ClocksIndex = (props: IClocksIndex) => {
    return (
        <>
            <EditExamDropDownRouter children={props.children} />
        </>
    )
}

export default ClocksIndex