import React from 'react'
import { HiMinusCircle } from 'react-icons/hi'
import { HiCalendar, HiCheckCircle, HiXCircle } from 'react-icons/hi2'


interface ILiveExamTimeLine {
    testData?: any,
    title?: any,
    descriptions?: any
    error?: any
    success?: any
}

const LiveExamTimeline = ({ testData, title, descriptions, error, success }: ILiveExamTimeLine) => {
    return (
        <li className="mb-10 ml-6">
            <span className={`flex absolute -left-3 justify-center items-center w-6 h-6 rounded-full ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900 ${error === true ? "bg-red-200" : success === true ? "bg-green-200" : "bg-grey-200"}`}>
                {error === true ? <HiXCircle className='text-red-600' /> : success === true ? <HiCheckCircle className='text-green-600' /> : <HiMinusCircle className='text-grey-600' /> }
            </span>
            <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{testData}</time>
            <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">{title}</h3>
            <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">{descriptions}</p>
        </li>
    )
}

export default LiveExamTimeline