import React from "react";
import Responses from "./Responses";


export default function ResponsesIndex () {
    return (
        <>
            <Responses/>

        </>
    )
}