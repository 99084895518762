import { Table } from 'flowbite-react'
import React, { useEffect, useMemo, useState } from 'react'
import { HiPencil } from 'react-icons/hi';
import CreateCenterEditModal from '../Modals/CenterEditModal';

interface ICentersTable {
  tableData: any;
  updateTable: any;
}
const CentersTable = ({
  tableData,
  updateTable,
}: ICentersTable) => {
 
  const [showEdit, setShowEdit] = useState<any>(undefined);

  const handleUpdate = (updateRecord: any) => {
    const otherRecords = tableData.filter((data: any) => data.centerId !== updateRecord.centerId);

    updateTable([...otherRecords, updateRecord]);

  }

  return (
    <div>
      <Table hoverable={true}>
        <Table.Head>
          <Table.HeadCell>Sr. No.</Table.HeadCell>
          <Table.HeadCell>Center Id</Table.HeadCell>
          <Table.HeadCell>Candidates</Table.HeadCell>
          <Table.HeadCell>Server Count</Table.HeadCell>
          <Table.HeadCell>Biometric Per Server</Table.HeadCell>
          <Table.HeadCell>Actions</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          
          {tableData && tableData.length > 0
            ? tableData.map((data: any, index: number) => {
                return (
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {index + 1}
                    </Table.Cell>
                    <Table.Cell>
                      {data?.centerId}
                    </Table.Cell>
                    <Table.Cell>
                      {data?.candidatesCount}
                    </Table.Cell>
                    <Table.Cell>
                      {data?.serverCount}
                    </Table.Cell>
                    <Table.Cell>
                      {/*  */}
                      {data?.biometricDevicesPerServer}
                    </Table.Cell>
                    <Table.Cell>
                      <span
                        className="text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
                        onClick={() => {}}
                      >
                        <HiPencil
                          className="text-blue-600"
                          onClick={() => {
                            setShowEdit(data)
                          }}
                        />
                      </span>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            : "No Centers data found"}
        </Table.Body>
      </Table>
      <CreateCenterEditModal
        show={showEdit ? true : false}
        setShow={setShowEdit}
        centerData={showEdit}
        handleDataSave={(update:any) => {
          handleUpdate(update);
        }}
      />
    </div>
  );
};

export default CentersTable;