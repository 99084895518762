import IRouter from "../interfaces/IRouter";
import { HiBookOpen } from "react-icons/hi2";
import ExamStatsIndex from "../views/Live/ExamStats/ExamStats.index";

const editliveexamrouter: IRouter[] = [
  {
    path: "examStats",
    element: <ExamStatsIndex />,
    navbarShow: true,
    name: "Exam-Stats",
    icon: HiBookOpen,
  },
];

export default editliveexamrouter;
