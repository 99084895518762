import { Button, Card, Label } from "flowbite-react";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useParams } from "react-router";
import ExamService from "../../../services/private/Exam/exam.service";
import MonitoringServices from "../../../services/private/Exam/monitoring.services";
import { toast } from "react-hot-toast";
import ZonesTable from "../../../components/Tables/zones.table";
import CandidateDevicesTable from "../../../components/Tables/CadidateDevices.table";
import SuspectsTable from "../../../components/Tables/Suspects.table";

export default function LastSyncLog() {
  const { examId } = useParams();

  const [centers, setCenters] = useState<any>();
  const [selectedNames, setSelectedNames] = useState<any>([]);
  const [shifts, setShifts] = useState<any>();
  const [zoneData, setZoneData] = useState<any>();
  const [data, setData] = useState<any>();
  const [shiftId, setShiftId] = useState<any>();

  const handleChange = (event: any) => {
    setSelectedNames(event);
  };

  const handleValueChange = (e: any) => {
    setZoneData({ ...zoneData, [e.target.name]: e.target.value });
  };

  const handleShiftValue = (e: any, name: any) => {
    setShiftId({ ...shiftId, [name]: e.value });
  };

  const getAllShifts = async () => {
    await ExamService.getExamShifts(examId).then((res) => {
      if (res.status === 200) {
        setShifts(
          res.data.map((data: any) => {
            return {
              value: data.shiftid,
              label: data.shiftname,
            };
          })
        );
      }
    });
  };

  const handleSearch = async () => {
    await MonitoringServices.getLastSyncLog(examId, shiftId.shiftId)
      .then((res: any) => {
        if (res.status === 200) {
          setData(res.data);
          console.log(res.data);
        } else setData(null);
      })
      .catch((err) => {
        toast.error("Unable to create zone");
        setData(null);
      });
  };

  useEffect(() => {
    getAllShifts();
  }, []);

  return (
    <div>
      <div className="my-10 mx-5 w-full">
        <Card>
          <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            Last Sync Log
          </h5>
          <div className="mt-1">
            <Label className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Select Shift
            </Label>
            <Select
              onChange={(e: any) => {
                handleShiftValue(e, "shiftId");
              }}
              options={shifts}
              isClearable={true}
              hideSelectedOptions={true}
              placeholder="Select Shift"
              className=""
            />
          </div>

          <div className="flex flex-col items-end mt-3">
            <Button size="sm" onClick={handleSearch}>
              GET LOG
            </Button>
          </div>
        </Card>
      </div>
      <div className="my-10 mx-5 w-full">
        {data ? (
          <Card>
            <div className="pr-5">
              <div>
                <span>Exam Type :-</span>
                <span className="pl-3 font-bold">
                  {data?.type ? data.type : "--"}
                </span>
              </div>
            </div>
            <div className="pr-5">
              <div>
                <span>User Id :-</span>
                <span className="pl-3 font-bold">
                  {data?.user ? data.user : "--"}
                </span>
              </div>
            </div>
            <div className="pr-5">
              <div>
                <span>Email :-</span>
                <span className="pl-3 font-bold">
                  {data?.email ? data.email : "--"}
                </span>
              </div>
            </div>
            <div className="pr-5">
              <div>
                <span>Logged In :-</span>
                <span className="pl-3 font-bold">
                  {data?.isLogin ? "Yes" : "No"}
                </span>
              </div>
            </div>
            <div className="pr-5">
              <div>
                <span>Active :-</span>
                <span className="pl-3 font-bold">
                  {data?.isActive ? "Yes" : "No"}
                </span>
              </div>
            </div>
            <div className="pr-5">
              <div>
                <span>Status :-</span>
                <span className="pl-3 font-bold">
                  {data?.status ? data.status : "--"}
                </span>
              </div>
            </div>
            <div className="pr-5">
              <div>
                <span>Device Info :-</span>
                <span className="pl-3 font-bold">
                  {data?.devInfo ? data.devInfo : "--"}
                </span>
              </div>
            </div>
            <div className="pr-5">
              <div>
                <span>Original URL :-</span>
                <span className="pl-3 font-bold">
                  {data?.originalUrl ? data.originalUrl : "--"}
                </span>
              </div>
            </div>
            <div className="pr-5">
              <div>
                <span>Exam Mapping Id :-</span>
                <span className="pl-3 font-bold">
                  {data?.examMappingId ? data.examMappingId : "--"}
                </span>
              </div>
            </div>
          </Card>
        ) : null}
      </div>
    </div>
  );
}
