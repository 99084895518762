import IRouter from "../../../interfaces/IRouter";
import { EditExamDropDownRouter } from "../../../Router";

interface IReservationIndex {
  children: IRouter[];
}

export default function HooksIndex(props: IReservationIndex) {
  return (
    <>
      <EditExamDropDownRouter children={props.children} />
    </>
  );
}
