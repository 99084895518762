import Auth from "../Auth";

export default function axiosHandler(error: any) {
  console.log(error, "axios handler")
  if (error.response) {
    if (error.response.status === 401) {

      Auth.signout();
      window.location.href = "/admin/login";
    }
  }
  return;
}
