import { Button, Label, Modal, TextInput } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import ExamService from '../../services/private/Exam/exam.service'

interface IEditBufferModal {
    show: any,
    onClose: any,
    data: any
}

const EditBufferModal = ({ show, onClose, data }: IEditBufferModal) => {

    const [buffer_data, setBuffer_data] = useState<any>({});

    const params: any = useParams();

    const updateBufferData = async () => {
        if(!buffer_data && (!buffer_data.category || !buffer_data.timeBuffer)){
            return false;
        }
        await ExamService.updateTimeBuffer(params.examId, {category: buffer_data.category, timeBuffer: buffer_data.timeBuffer}).then((res: any) => {
            if (res.status === 200) {
                toast.success("Buffer Updated");
                onClose();
            }
        }).catch((err) => {
            toast.error("Unable to update buffer")
        })
    }

    useEffect(() => {
        setBuffer_data(data);
    },[show])

    return (
        <>
            <Modal
                show={show}
                size="md"
                popup={show}
                onClose={onClose}
            >
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                            Edit Buffer
                        </h3>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="Time (in minutes)"
                                    value="Time (in minutes)"
                                />
                            </div>
                            <TextInput
                                type="number"
                                name="timeBuffer"
                                defaultValue={data?.timeBuffer}
                                onChange={(e:any) => setBuffer_data({...buffer_data, timeBuffer: e.target.value})}
                                required={true}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="password"
                                    value="Category"
                                />
                            </div>
                            <TextInput
                                type="text"
                                name="category"
                                value={data?.category}
                                disabled={true}
                            />
                        </div>
                        <div className="w-full">
                            <Button onClick={updateBufferData}>
                                Update Buffer
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditBufferModal