import { Button, Label, Modal, TextInput } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import ExamService from '../../services/private/Exam/exam.service';

interface IEditShiftModal {
    show: any,
    onClose: any
    data: any
}


const EditShiftModal = ({ show, onClose, data }: IEditShiftModal) => {

    const [shift_data, setShift_data] = useState<any>({});

    const {examId}:any = useParams();

    useEffect(()=>{
        setShift_data(data)
    },[show])

    const onChangeShiftData = (e: any) => {
        console.log(e.target.value,"shiftData");
        setShift_data({...shift_data, [e.target.name]: e.target.value})
    }

    const onUpdateShift = async() => {
       await ExamService.updateShift(examId, shift_data.shiftid, {
            shiftname: shift_data.shiftname,
            shiftstartdateandtime: shift_data.shiftstartdateandtime,
            shiftenddateandtime: shift_data.shiftenddateandtime,
        }).then(res=>{
            if(res.status === 200){
                onClose()
                toast.success("Updated Values")
            }
        })
    }

    return (
        <>
            <Modal
                show={show}
                size="md"
                popup={show}
                onClose={onClose}
            >
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                            Edit Shift
                        </h3>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="Time (in minutes)"
                                    value="Shift name"
                                />
                            </div>
                            <TextInput
                                id="number"
                                type="text"
                                name="shiftname"
                                value={shift_data && shift_data.shiftname}
                                onChange={onChangeShiftData}
                                required={true}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="password"
                                    value="Shift start time"
                                />
                            </div>
                             <DatePicker
                                selected={shift_data && new Date(shift_data.shiftstartdateandtime)}
                                onChange={(data:any) => {
                                    const e = { 
                                        target: {
                                            name: "shiftstartdateandtime",
                                            value: data
                                        }
                                    }
                                    onChangeShiftData(e) 
                                }}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                showTimeSelect
                                dateFormat="MMM d, yyyy h:mm aa"
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="password"
                                    value="Shift start time"
                                />
                            </div>
                            <DatePicker
                                selected={shift_data && new Date(shift_data.shiftenddateandtime)}
                                onChange={(data:any) => {
                                    const e = { 
                                        target: {
                                            name: "shiftenddateandtime",
                                            value: data
                                        }
                                    }
                                    onChangeShiftData(e) 
                                }}
                                showTimeSelect
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                dateFormat="MMM d, yyyy h:mm aa"
                            />
                        </div>
                        <div className="w-full">
                            <Button onClick={onUpdateShift}>
                                Update Shift
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default EditShiftModal