import IRouter from "../interfaces/IRouter";
import { HiStatusOnline } from "react-icons/hi";
import EditLiveExamIndex from "../views/Live/EditLiveExam/EditLiveExam.index";
import monitoringcandidatedevicesrouter from "./monitoringcandidatedevices.router";
import monitoringlabdevicesrouter from "./monitoringlabdevices.router";
import monitoringsuspectsrouter from "./monitoringsuspects.router";
import monitoringlastsynclogrouter from "./monitoringlastsynclog.router";
import monitoringextratimerouter from "./monitoringextratime.router.tsx";

export const baseurl = "/monitoring/";

const monitoringRoutes: IRouter[] = [
  {
    path: "candidate",
    element: <EditLiveExamIndex children={monitoringcandidatedevicesrouter} />,
    navbarShow: true,
    name: "Candidate",
    icon: HiStatusOnline,
    children: monitoringcandidatedevicesrouter,
    baseUrl: baseurl,
  },

  {
    path: "lab-devices",
    element: <EditLiveExamIndex children={monitoringlabdevicesrouter} />,
    navbarShow: true,
    name: "Lab Devices",
    icon: HiStatusOnline,
    children: monitoringlabdevicesrouter,
    baseUrl: baseurl,
  },

  {
    path: "suspects",
    element: <EditLiveExamIndex children={monitoringsuspectsrouter} />,
    navbarShow: true,
    name: "Suspects",
    icon: HiStatusOnline,
    children: monitoringsuspectsrouter,
    baseUrl: baseurl,
  },

  {
    path: "lastsynclog",
    element: <EditLiveExamIndex children={monitoringlastsynclogrouter} />,
    navbarShow: true,
    name: "Last Sync Log",
    icon: HiStatusOnline,
    children: monitoringlastsynclogrouter,
    baseUrl: baseurl,
  },

  {
    path: "extra-timings",
    element: <EditLiveExamIndex children={monitoringextratimerouter} />,
    navbarShow: true,
    name: "Extra Timings",
    icon: HiStatusOnline,
    children: monitoringextratimerouter,
    baseUrl: baseurl,
  },
];

export default monitoringRoutes;
