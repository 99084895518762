import { Table } from "flowbite-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import AdminService from "../../../services/private/Admin/admin.service";

export default function LiveExams() {
  let [exams, setExams] = useState([]);
  let [isChecked, setIsChecked] = useState<any>(false);
  const getAllExams = async () => {
    await AdminService.getAllLiveExams().then((res: any) => {
      console.log(res?.data, "liveExams");
      setExams(res.data);
    });
  };

  const updateLiveExam = async (uuid: any, e: any) => {
    setIsChecked({ [e.target.name]: e.target.checked });
    await AdminService.updatelLiveExams(uuid, isChecked)
      .then((res: any) => {
        toast.success("Exam is updated");
      })
      .catch(() => {
        toast.error("An Error occured while toggling exam");
      });
  };

  useEffect(() => {
    getAllExams();
  }, []);

  return (
    <>
      <div className="m-5 ">
        <div className="flex justify-between mb-3">
          <div className="text-xl font-semibold text-gray-900 mx-3">
            ALL LIVE EXAMS
          </div>
        </div>
        <Table striped={true}>
          <Table.Head>
            <Table.HeadCell>#</Table.HeadCell>
            <Table.HeadCell>Exam Name</Table.HeadCell>
            <Table.HeadCell>Exam Timing</Table.HeadCell>
            {/* <Table.HeadCell>Actions</Table.HeadCell> */}
            <Table.HeadCell>View</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {exams && exams.length > 0
              ? exams.map((exam: any, index: number) => {
                  return (
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {index + 1}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {exam.examName}
                      </Table.Cell>

                      <Table.Cell>
                        {new Date(exam.activeFrom).toLocaleDateString("en-IN")}{" "}
                        -{" "}
                        {new Date(exam.activeUpto).toLocaleDateString("en-IN")}
                      </Table.Cell>

                      {/* <Table.Cell>
                        <label className="inline-flex relative items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value={isChecked.checked}
                            className="sr-only peer"
                            defaultChecked={exam?.isLive}
                            onChange={(e: any) => {
                              updateLiveExam(exam.uuid, e);
                            }}
                            name="isLive"
                          />
                          <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Checked toggle
                          </span>
                        </label>
                      </Table.Cell> */}
                      <Table.Cell>
                        <a
                          href={"/liveExam/" + exam.uuid + "/examstats"}
                          className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                        >
                          View
                        </a>
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              : "No Live Exam Found"}
          </Table.Body>
        </Table>
      </div>
    </>
  );
}
