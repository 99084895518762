import makeRequest, { RequestMethod } from "../../make.request";
import urls from "../../urls";

export default class MonitoringServices {
  static async getCandidateDevices(examId: any, shiftId: string) {
    return await makeRequest(
      urls.protected.monitoring.getCandidateDevices +
        "/" +
        examId +
        "/" +
        shiftId,
      RequestMethod.GET
    );
  }

  static async getCandidateTimings(examId: any, shiftId: string) {
    return await makeRequest(
      urls.protected.monitoring.getCandidateTimings +
        "/" +
        examId +
        "/" +
        shiftId,
      RequestMethod.GET
    );
  }

  static async getLabDevices(examId: any, shiftId: string) {
    return await makeRequest(
      urls.protected.monitoring.getLabDevices + "/" + examId + "/" + shiftId,
      RequestMethod.GET
    );
  }

  static async getSuspects(examId: any, shiftId: string) {
    return await makeRequest(
      urls.protected.monitoring.getSuspects + "/" + examId + "/" + shiftId,
      RequestMethod.GET
    );
  }

  static async getLastSyncLog(examId: any, shiftId: string) {
    return await makeRequest(
      urls.protected.monitoring.getLastSyncLog + "/" + examId + "/" + shiftId,
      RequestMethod.GET
    );
  }

  static async getExtraTimings(examId: any, shiftId: string) {
    return await makeRequest(
      urls.protected.monitoring.getExtraTimings + "/" + examId + "/" + shiftId,
      RequestMethod.GET
    );
  }
}
