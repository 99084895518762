import IRouter from "../interfaces/IRouter";
import { HiBookOpen } from "react-icons/hi2";
import ExamStatsIndex from "../views/Live/ExamStats/ExamStats.index";
import CandidateDevices from "../views/Monitoring/CandidateDevices/CandidateDevices.index";
import CandidateTimings from "../views/Monitoring/CandidateTImings/CandidateTimings.index";
import LabDevices from "../views/Monitoring/LabDevices/LabDevices.index";

const monitoringlabdevicesrouter: IRouter[] = [
  {
    path: "",
    element: <LabDevices />,
    navbarShow: true,
    name: "Lab Devices",
    icon: HiBookOpen,
  },

];

export default monitoringlabdevicesrouter;
