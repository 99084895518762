import { Sidebar } from 'flowbite-react';
import { HiShoppingBag, HiChartPie } from 'react-icons/hi2';
import { useLocation, useNavigate } from 'react-router';
import { useEffect } from 'react';
import examRoutes from '../../router/exam.router';
import { useParams } from 'react-router';

interface IExamSidebar {
    render: any
}

interface IRenderCollapse {
    icon: any,
    label: string
    subUrls: {
        value: string,
        href: string,
        icon: any
    }[];
}


interface IRenderStatic {
    href: string;
    icon: any;
    label: string
}


const RenderCollapse = (props: IRenderCollapse) => {
    
    const navigation = useNavigate();

    return (
        <Sidebar.Collapse
            icon={props.icon}
            label={props.label}
            className="font-light text-sm"
            style={{ fontSize: "10px !important" }}
        >
            {props.subUrls.map((data) => {
                return (<>
                    <Sidebar.Item
                        onClick={() => navigation(data.href)}
                        className="cursor-pointer"
                        >
                        <span className='text-sm text-blue-900 flex justify-start items-center'><data.icon className="mr-1"/> {data.value}</span>
                    </Sidebar.Item>
                </>)
            })}

        </Sidebar.Collapse>
    )
}

const RenderStatic = (props: IRenderStatic) => {
    const navigation = useNavigate();

    return (

        <Sidebar.Item
            onClick={() => navigation(props.href)}
            icon={props.icon}
            className="cursor-pointer"
        >
            {props.label}
        </Sidebar.Item>

    )
}

export default function ExamSidebar(props: IExamSidebar) {

    const location = useLocation();

    useEffect(() => {
        const path = location.pathname.split('/')[1];
    }, [location.pathname])

    const params: any = useParams();

    return (
      <div className="w-fit flex justify-start">
        <Sidebar
          aria-label="Sidebar with multi-level dropdown example"
          className="border-r max-h-full"
        >
          <Sidebar.Items>
            <Sidebar.ItemGroup>
              {examRoutes.map((data) => {
                if (data.children && data.children.length > 0) {
                  return (
                    <RenderCollapse
                      icon={data.icon}
                      label={data.name}
                      subUrls={data.children.map((child) => {
                        return {
                          value: child.name,
                          href:
                            data.baseUrl +
                            params.examId +
                            "/" +
                            data.path +
                            "/" +
                            child.path,
                          icon: child.icon,
                        };
                      })}
                    />
                  );
                } else {
                  return (
                    <RenderStatic
                      icon={data.icon}
                      label={data.name}
                      href={data.baseUrl + params.examId + "/" + data.path}
                    />
                  );
                }
              })}
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
        {props.render}
      </div>
    );
}