import { Button, Card } from 'flowbite-react';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import EditHooksModal from '../../../../components/Modals/EditHooks.modal';
import ExamService from '../../../../services/private/Exam/exam.service';

const Exmslv = () => {
    const { examId } = useParams();

    const [exmslvData, setExmslvData] = useState<any>();
    const [showSlvModal, setShowSlvModal] = useState<boolean>(false)




    const getHooksData = async () => {
        await ExamService.getHooksData(examId).then((res: any) => {
            if (res.status === 200) {
                setExmslvData({
                    EXMSLV: res?.data?.EXMSLV,
                    EXMSLV_KEY: res?.data?.EXMSLV_KEY
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    const onUpdate = async () => {

        await ExamService.updateExamUrls(examId, { EXMSLV: exmslvData?.EXMSLV, EXMSLV_KEY: exmslvData?.EXMSLV_KEY }).then((res) => {
            if (res.status === 200) {
                toast.success("Succesfully Updated");
                setShowSlvModal(false);
                getHooksData();
            }
        }).catch((err) => {
            toast.error("Failed to Update");
        });
    };

    useEffect(() => {
        getHooksData();
    }, []);

    return (
        <div className="ml-10 mt-10">
            <Card>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        EXMSLV
                    </h5>
                    <Button onClick={() => setShowSlvModal(true)}>
                        Edit
                    </Button>
                </div>
                <Card>
                    <div>
                        <p className="font-bold text-gray-700 dark:text-gray-400">
                            URL: {" "}{" "}{exmslvData ? exmslvData?.EXMSLV : "--"}
                        </p>
                        <p className="font-bold text-gray-700 dark:text-gray-400">
                            KEY: {" "}{" "}{exmslvData ? exmslvData?.EXMSLV_KEY : "--"}
                        </p>
                    </div>
                </Card>
            </Card>
            <EditHooksModal
                show={showSlvModal}
                onClose={() => setShowSlvModal(false)}
                stateData={exmslvData}
                onChange={(new_state: any) => { setExmslvData(new_state) }}
                onSubmission={onUpdate}
            />
        </div>
    )
}

export default Exmslv
