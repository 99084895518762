import { Button, Card, Label, Modal, Table, TextInput } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import ExamService from '../../../services/private/Exam/exam.service';

const CenterSettings = () => {
    const { examId } = useParams();
    const [tableData, setTableData] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [centerSettingData, setCenterSettingData] = useState<any>();

    const handleChangeValue = (e: any) => {
        setCenterSettingData({ ...centerSettingData, [e.target.name]: e.target.value });
    }

    const getExmsteServerAndBioDevices = async () => {
        await ExamService.getExmsteServerAndBioDevices(examId).then((res: any) => {
            if (res.status === 200) {
                setTableData(res.data);
            }
        })
    }

    const handleEditCenterSettings = async () => {
        await ExamService.editBioDevicesAndExmsteCount(examId, {
            exmbioDevices: Number(centerSettingData.exmbioDevices) || tableData.biometric_devices,
            exmsteServers: Number(centerSettingData.exmsteServers) || tableData.exmste_servers
        }).then((res) => {
            if (res.status === 200) {
                toast.success("Center Setting Edited Successfully.");
                setShowModal(false);
                getExmsteServerAndBioDevices();

            }
        }).catch(err => {
            toast.error(err)
        })
    }

    useEffect(() => {
        getExmsteServerAndBioDevices();
    }, [])


    return (
        <>
            <div className="ml-10 mt-10">
                <Card>
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        Center Settings
                    </h5>
                    <Table hoverable={true}>
                        <Table.Head>
                            <Table.HeadCell>
                                STE Devices (per student)
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Bio Devices (per student)
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Action
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell>
                                    {tableData ? tableData.exmste_servers : "--"}
                                </Table.Cell>
                                <Table.Cell>
                                    {tableData ? tableData.biometric_devices : "--"}
                                </Table.Cell>
                                <Table.Cell>
                                    <span
                                        className="font-medium text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
                                        onClick={() => { setShowModal(true) }}
                                    >
                                        Edit
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Card>
            </div>
            <Modal
                show={showModal}
                size="md"
                popup={showModal}
                onClose={() => setShowModal(false)}
            >
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                            Edit Center Settings
                        </h3>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="Time (in minutes)"
                                    value="EXMSTE Server"
                                />
                            </div>
                            <TextInput
                                id="number"
                                name="exmsteServers"
                                defaultValue={tableData?.exmste_servers}
                                required={true}
                                onChange={handleChangeValue}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="Time (in minutes)"
                                    value="EXMBIO Devices"
                                />
                            </div>
                            <TextInput
                                id="number"
                                name="exmbioDevices"
                                defaultValue={tableData?.biometric_devices}
                                required={true}
                                onChange={handleChangeValue}
                            />
                        </div>
                        <div className="w-full">
                            <Button onClick={handleEditCenterSettings}>
                                Edit
                            </Button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default CenterSettings