import React from "react";
import { Routes, Route, Navigate } from "react-router";
import Auth from "./Auth";
import AdminHome from "./home/Admin.home";
import ExamHome from "./home/Exam.home";
import LiveExamHome from "./home/LiveExam.home";
import IRouter from "./interfaces/IRouter";
import adminRoutes from "./router/admin.router";
import examRoutes from "./router/exam.router";
import liveExamRoutes from "./router/live.router";
import AdminIndex from "./views/Admin/Admin.index";
import LoginIndex from "./views/Login/Login.index";
import MonitoringHome from "./home/Monitoring.home";
import monitoringRoutes from "./router/monitoring.router";

interface IiEditExamDropDownRouter {
  children: IRouter[];
}

function AdminRouter({ children }: any) {
  const auth = Auth.checkAuth(); //return true or false everytime
  return auth ? <AdminHome /> : <Navigate to="/login" />; // if user is already logged in then redirect to dashboard else redirect to login page
}

function ExamRouter({ children }: any) {
  const auth = Auth.checkAuth(); //return true or false everytime
  return auth ? <ExamHome /> : <Navigate to="/login" />; // if user is already logged in then redirect to dashboard else redirect to login page
}

function LiveExamRouter({ children }: any) {
  const auth = Auth.checkAuth(); //return true or false everytime
  return auth ? <LiveExamHome /> : <Navigate to="/login" />; // if user is already logged in then redirect to dashboard else redirect to login page
}

function MonitoringRouter({ children }: any) {
  const auth = Auth.checkAuth(); //return true or false everytime
  return auth ? <MonitoringHome /> : <Navigate to="/login" />; // if user is already logged in then redirect to dashboard else redirect to login page
}

export function EditExamDropDownRouter(props: IiEditExamDropDownRouter) {
  return (
    <Routes>
      {props.children.map((data) => {
        return <Route path={data.path} element={data.element} />;
      })}
    </Routes>
  );
}

export function EditLiveExamDropDownRouter(props: IiEditExamDropDownRouter) {
  return (
    <Routes>
      {props.children.map((data) => {
        return <Route path={data.path} element={data.element} />;
      })}
    </Routes>
  );
}

export function EditMonitoringDropDownRouter(props: IiEditExamDropDownRouter) {
  return (
    <Routes>
      {props.children.map((data) => {
        return <Route path={data.path} element={data.element} />;
      })}
    </Routes>
  );
}

export function FinalAnswerSheetRouter(props: IiEditExamDropDownRouter) {
  return (
    <Routes>
      {props.children.map((data) => {
        return <Route path={data.path} element={data.element} />;
      })}
    </Routes>
  );
}

export default function MainRouter() {
  return (
    <>
      <Routes>
        {/*path is dashboard the render privateRounter */}
        <Route path="admin" element={<AdminRouter />}>
          {adminRoutes.map((data: IRouter) => {
            // data will follow poperty of IRouter
            return <Route path={data.path + "/*"} element={data.element} />;
          })}
        </Route>

        <Route path="exam" element={<ExamRouter />}>
          {examRoutes.map((data: IRouter) => {
            console.log(data, "exam router");
            // data will follow poperty of IRouter
            return (	
              <Route
                path={":examId" + "/" + data.path + "/*"}
                element={data.element}
              />
            );
          })}
        </Route>

        <Route path="liveExam" element={<LiveExamRouter />}>
          {liveExamRoutes.map((data: IRouter) => {
            console.log(data, "exam router");
            // data will follow poperty of IRouter
            return (
              <Route
                path={":examId" + "/" + data.path + "/*"}
                element={data.element}
              />
            );
          })}
        </Route>

        <Route path="monitoring" element={<MonitoringRouter />}>
          {monitoringRoutes.map((data: IRouter) => {
            console.log(data, "exam router");
            // data will follow poperty of IRouter
            return (
              <Route
                path={":examId" + "/" + data.path + "/*"}
                element={data.element}
              />
            );
          })}
        </Route>

        {/* if path is login go for login*/}
        <Route path="/login" element={<LoginIndex />} />

        {/* if path is * then go for /login using navigate component */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </>
  );
}