import makeRequest, { RequestMethod } from "../../make.request";
import urls from "../../urls";

const base=urls.protected.reservation;

export default class ReservationService {
  static async getAllCenters() {
    return await makeRequest(base.getAllCenters, RequestMethod.GET);
  }
  static async getCenterById(centerId: any) {
    return await makeRequest(base.getCenterById + "/" + centerId, "get");
  }
  static async getCenterArrCapacity(centerArr: any) {
    const capacity: any = await makeRequest(
      base.getCentersArrCapacity,
      "post",
      { centersArr: centerArr }
    );
    return capacity.data.totalSeats;
  }
  static async lockExamBooking(examId: any) {
    return await makeRequest(base.lockExamBooking + "/" + examId, "put");
  }
  static async unLockExamBooking(examId: any) {
    return await makeRequest(base.unLockExamBooking + "/" + examId, "put");
  }
  static async createCenterBooking(examId: any, test: any) {
    return await makeRequest(
      base.createCenterBooking + "/" + examId,
      "post",
      test
    );
  }

  static async finalizeCenterBooking(examId: any, test: any) {
    return await makeRequest(
      base.createCenterBooking + "/" + examId,
      "post",
      test
    );
  }
  static async removeCenterBooking(examId: any) {
    return await makeRequest(base.deleteCenterBooking + "/" + examId, "delete");
  }
  static async getCenterBookingLockStatus(examId: any) {
    return await makeRequest(
      base.getCenterBookingLockStatus + "/" + examId,
      "get"
    );
  }
  static async getCenterArrDetails(centersArr: any) {
    return await makeRequest(base.getCenterArrDetails, "post", {
      centersArr: centersArr,
    });
  }
  static async getExamBookingLockStatus(examId: any) {
    return await makeRequest(
      base.getExamBookingLockStatus + "/" + examId,
      "get"
    );
  }
  static async getTempCenters(examId: any) {
    return await makeRequest(base.getTempCenters + "/" + examId, "get");
  }
  static async lockTempCenters(examId: any) {
    return await makeRequest(base.lockTempCenters + "/" + examId, "put");
  }
  static async saveTempCenters(examId: any, centers: any) {
    return await makeRequest(
      base.saveTempCenters + "/" + examId,
      "post",
      centers
    );
  }
  static async unlockTempCenters(examId: any) {
    return await makeRequest(base.unlockTempCenters + "/" + examId, "put");
  }
  static async checkReservationInit(examId: any) {
    return await makeRequest(base.checkReservationInit + "/" + examId, "get");
  }
  static async getCentersForBookingWithLabel(examId: any) {
    return await makeRequest(
      base.getCentersForBookingWithLabel + "/" + examId,
      "get"
    );
  }
  static async getChoicesDist(examId: any) {
    return await makeRequest(base.getChoicesDist + "/" + examId, "get");
  }
  static async getStudentsinDistrictWithChoice(
    examId: any,
    cityName: any,
    choiceNumber: any
  ) {
    return await makeRequest(
      base.getStudentsinDistrictWithChoice + "/" + examId,
      "post",
      { cityName, choiceNumber }
    );
  }
  static async getStudentReservationByCenterIdandShiftId(
    examId: any,
    centerId: any,
    shiftId: any
  ) {
    return await makeRequest(
      base.getStudentReservationByCenterIdandShiftId + examId,
      "post",
      { centerId, shiftId }
    );
  }
  static async getStudentReservationStats(examId: any) {
    return await makeRequest(
      base.getStudentReservationStats + "/" + examId,
      "get"
    );
  }
  static async allotCenters(
    examId: any,
    centerId: any,
    shiftId: any,
    candidates: any
  ) {
    return await makeRequest(base.allotCenters + "/" + examId, "post", {
      centerId,
      shiftId,
      candidates,
    });
  }
  static async deleteBookingForCenterAndShift(
    examId: any,
    centerId: any,
    shiftId: any
  ) {
    return await makeRequest(
      base.clearBookingForCenterinShift + "/" + examId,
      "delete",
      {
        centerId,
        shiftId,
      }
    );
  }
  static async deleteBookingsForStudents(examId: any, studentIds: any) {
    return await makeRequest(
      base.clearBookingForStudentsInExam + "/" + examId,
      "delete",
      {
        studentIds,
      }
    );
  }
  static async getCentersLabData(examId?: any) {
    return await makeRequest(base.getCentersLabData + "/" + examId, "get");
  }
  static async getLabById(examId?: any) {
    return await makeRequest(base.getLabById + "/" + examId, "get");
  }
}