import React, { useEffect, useState } from 'react'
import { Table } from 'flowbite-react'
import { useParams } from 'react-router'
import moment from 'moment'

interface ILiveCenterTable {
  tableData: any
}

const LiveCenterTable = ({ tableData }: ILiveCenterTable) => {
  return (
    <div>
      <Table>
        <Table.Head>
          <Table.HeadCell>
            Sr. No.
          </Table.HeadCell>
          <Table.HeadCell>
            Username
          </Table.HeadCell>
          <Table.HeadCell>
           Bio Users
          </Table.HeadCell>
          <Table.HeadCell>
           Center Id
          </Table.HeadCell>
          <Table.HeadCell>
            Login Attempts
          </Table.HeadCell>
          <Table.HeadCell>
           Download Data Status
          </Table.HeadCell>
          <Table.HeadCell>
           Blocked
          </Table.HeadCell>
          <Table.HeadCell>
           Last Login
          </Table.HeadCell>
          <Table.HeadCell>
           Last Sync Time
          </Table.HeadCell>
          <Table.HeadCell>
           Public Key
          </Table.HeadCell>

          <Table.HeadCell>
           Soc. Id.
          </Table.HeadCell>
          <Table.HeadCell>
           Cad. Submitted
          </Table.HeadCell>
          <Table.HeadCell>
           Cad. Ongoing
          </Table.HeadCell>

          
        </Table.Head>
        <Table.Body className="divide-y">
          {tableData && tableData.length > 0 ? tableData.map((data: any, index: number) => {
            return (
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell>
                  {index + 1}
                </Table.Cell>
                <Table.Cell>
                  {data ? data.username : "--"}
                </Table.Cell>
                <Table.Cell>
                  {data ? data.allowedBioRegUsers : "--"}
                </Table.Cell>
                <Table.Cell>
                  {data ? data.center_id : "--"}
                </Table.Cell>
                <Table.Cell>
                  {data ? data.incorrect_attempts : "--"}
                </Table.Cell>
                <Table.Cell>
                  {data ? "Yes" : "--"}
                </Table.Cell>

                <Table.Cell>
                  {data ? String(data.is_block) : "--"}
                </Table.Cell>
                <Table.Cell>
                  {data && data.lastLogin ? moment(data.lastLogin).format("DD MMM YY, hh:mm") : "--"}
                </Table.Cell>
                <Table.Cell>
                  {data && data.lastSyncTime ? moment(data.lastSyncTime).format("DD MMM YY, hh:mm") : "--"}
                </Table.Cell>
                <Table.Cell>
                  {data && data.publicKey ? "Yes" : "No"}
                </Table.Cell>
                <Table.Cell>
                  {data && data.socketId? data.socketId : "--"}
                </Table.Cell>

                <Table.Cell>
                  {"0"}
                </Table.Cell>
                <Table.Cell>
                  {"0"}
                </Table.Cell>
                
              </Table.Row>
            )
          }) : "No data available"}
        </Table.Body>
      </Table>
    </div>
  )
}

export default LiveCenterTable