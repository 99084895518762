import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import Auth from "../Auth";
import AdminSidebar from "../components/Sidebar/Admin.sidebar";
import Topbar from "../components/TopBar/Topbar";
import ExamIndex from "../views/Exam/Exam.index";
import LiveExamsIndex from "../views/Live/Live.index";
import MonitoringIndex from "../views/Monitoring/Monitoring.index";

export default function MonitoringHome() {
  let navigate = useNavigate();

  useEffect(() => {
    if (!Auth.checkAuth()) navigate("/login");
  });

  return (
    <div>
      <Topbar />
      <AdminSidebar render={<MonitoringIndex render={<Outlet />} />} />
    </div>
  );
}
