import { Badge, Button, Table } from "flowbite-react";
import { useEffect, useState } from "react";
import { HiPlus } from "react-icons/hi";
import AdminService from "../../../services/private/Admin/admin.service";
import CreateExamModal from "../../../components/Modals/CreateExamModal";

export default function Exams() {

    let [exams, setExams] = useState([]);
    let [show, setShow] = useState<boolean>(false);

    const getAllExams = async () => {
        await AdminService.getAllExams().then((res: any) => {
            setExams(res.data);
        })
    }

    useEffect(() => {
        getAllExams()
    }, [show])

    return (
        <>
            <CreateExamModal show={show} setShow={setShow} />
            <div className="m-5 ">
                <div className="flex justify-between mb-3">
                    <div className="text-xl font-semibold text-gray-900 mx-3">ALL EXAMS</div>
                    <Button
                        size="sm"
                        onClick={() => setShow(true)}
                    ><HiPlus className="mr-2" />{"  "}Create</Button>
                </div>
                <Table striped={true}>
                    <Table.Head>
                        <Table.HeadCell>
                            #
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Exam Name
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Exam Timing
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Active
                        </Table.HeadCell>
                        <Table.HeadCell>
                            View
                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {exams.map((exam: any, index: number) => {
                            return <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    {index + 1}
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    {exam.examName}
                                </Table.Cell>

                                <Table.Cell>

                                    {new Date(exam.activeFrom).toLocaleDateString('en-IN')} - {
                                        new Date(exam.activeUpto).toLocaleDateString('en-IN')
                                    }
                                </Table.Cell>

                                <Table.Cell>
                                    <div className="w-16">
                                        {exam.allowed ?
                                            <Badge color="success">
                                                ACTIVE
                                            </Badge>
                                            :
                                            <Badge color="danger">
                                                NOT ACTIVE
                                            </Badge>
                                        }
                                    </div>
                                </Table.Cell>

                                <Table.Cell>
                                    <a
                                        href={"/exam/" + exam.uuid + '/settings'}
                                        className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                                    >
                                        View
                                    </a>
                                </Table.Cell>
                            </Table.Row>
                        })}


                    </Table.Body>
                </Table>
            </div>
        </>
    )
}