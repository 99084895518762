import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import { useParams } from "react-router";
import moment from "moment";

interface IResponsesTable {
  tableData: any;

}

const ResponsesTable = ({ tableData }: IResponsesTable) => {
  console.log(tableData);
  return (
    <div>
      <Table>
        <Table.Head>
          <Table.HeadCell>Sr. No.</Table.HeadCell>
          <Table.HeadCell>Center Id</Table.HeadCell>
          <Table.HeadCell>Shift Id</Table.HeadCell>
          <Table.HeadCell>Student Id</Table.HeadCell>
          <Table.HeadCell>Mapping Id</Table.HeadCell>
          <Table.HeadCell>Updated On</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {tableData && tableData.length > 0
            ? tableData.map((data: any, index: number) => {
              return (
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{data ? data.centerId : "--"}</Table.Cell>
                  <Table.Cell>{data ? data.shiftId : "--"}</Table.Cell>
                  <Table.Cell>{data ? data.studentId : "--"}</Table.Cell>
                  <Table.Cell>{data ? data.mappingId : "--"}</Table.Cell>
                  <Table.Cell>{data && data.updatedOn ? data.updatedOn : moment().subtract("10", "days").format("DD MMM YY, hh:mm")}</Table.Cell>
                </Table.Row>
              );
            })
            : "No data available"}
        </Table.Body>
      </Table>
    </div>
  );
};

export default ResponsesTable;
