import { HiBookOpen } from "react-icons/hi";
import IRouter from "../interfaces/IRouter";
import Exmbnk from "../views/Exam/Hooks/ExamBnk/Exmbnk";
import Exmcen from "../views/Exam/Hooks/ExamCen/Exmcen";
import Exmreg from "../views/Exam/Hooks/ExamReg/Exmreg";
import Exmslv from "../views/Exam/Hooks/ExamSlv/Exmslv";

const hooksRoutes: IRouter[] = [
  {
    path: "exam-reg",
    element: <Exmreg />,
    navbarShow: true,
    name: "EXMREG",
    icon: HiBookOpen,
  },
  {
    path: "exam-cen",
    element: <Exmcen />,
    navbarShow: true,
    name: "EXMCEN",
    icon: HiBookOpen,
  },
  {
    path: "exam-slv",
    element: <Exmslv />,
    navbarShow: true,
    name: "EXMSLV",
    icon: HiBookOpen,
  },
  {
    path: "exam-bnk",
    element: <Exmbnk />,
    navbarShow: true,
    name: "EXMBNK",
    icon: HiBookOpen,
  },
];

export default hooksRoutes;
