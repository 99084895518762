import IRouter from "../interfaces/IRouter";
import StudentUpload from "../views/Exam/Uploads/Student.upload";
import QuestionPaperIndex from "../views/Exam/Uploads/questionpaper.index";
import { AiFillBook } from "react-icons/ai";
import { HiOutlineUser, HiUser } from "react-icons/hi2";
import StudentStatus from "../views/Exam/Uploads/StudentStatus";
import SectionIndex from "../views/Exam/Sections/section.index";
import AnswerKeyUpload from "../views/Exam/Uploads/AnswerKeyUpload";
import { MdQuestionAnswer } from "react-icons/md";

const uploadRoutes: IRouter[] = [
  {
    path: "student",
    element: <StudentUpload />,
    navbarShow: true,
    name: "Student Data",
    icon: HiUser,
  },
  {
    path: "section-data",
    element: <SectionIndex />,
    navbarShow: true,
    name: "Section",
    icon: AiFillBook,
  },

  {
    path: "answerKey",
    element: <AnswerKeyUpload />,
    navbarShow: true,
    name: "Answer-key",
    icon: MdQuestionAnswer,
  },
  // SectionIndex
];

export default uploadRoutes;