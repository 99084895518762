import { Button, Card, Timeline } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { HiCalendar, HiCheckCircle, HiXCircle } from "react-icons/hi2";
import { useParams } from "react-router";
import LiveExamTimeline from "../../../components/Timeline/LiveExam.timeline";
import ExamService from "../../../services/private/Exam/exam.service";
import LiveService from "../../../services/private/Exam/live.service";

const TestLiveExam = () => {
  const { examId } = useParams();

  const [shiftError, setShiftError] = useState<boolean>(false);
  const [shiftMessage, setShiftMessage] = useState<any>();
  const [candidatesForCenterError, setCandidatesForCenterError] =
    useState<boolean>(false);
  const [candidatesForCenterMessage, setCandidatesForCenterMessage] =
    useState<any>();
  const [timeBufferError, setTimeBufferError] = useState<boolean>(false);
  const [timeBufferMessage, setTimeBufferMessage] = useState<any>();
  const [qpBankHooksMessage, setQpBankHooksMessage] = useState<any>();
  const [qpBankHooksError, setQpBankHooksError] = useState<boolean>(false);
  const [bioUsersError, setBioUsersError] = useState<boolean>(false);
  const [bioUsersMessage, setBioUsersMessage] = useState<any>();
  const [exmsteDataError, setExmsteDataError] = useState<boolean>(false);
  const [exmsteDataMessage, setExmsteDataMessage] = useState<any>();
  const [zoneError, setZoneError] = useState<boolean>(false);
  const [zoneMessage, setZoneMessage] = useState<any>();
  const [sectionError, setSectionError] = useState<boolean>(false);
  const [sectionMessage, setSectionMessage] = useState<any>();
  const [asymmetricKeysError, setAsymmetricKeysError] =
    useState<boolean>(false);
  const [asymmetricKeysMessage, setAsymmetricKeysMessage] = useState<any>();
  const [clockTimingError, setClockTimmingError] = useState<boolean>(false);
  const [questionPaperError, setQuestionPaperError] = useState<boolean>(false);

  const [clockTimingMessage, setlockTimingMessage] = useState<any>();
  const [questionPaperMessage, setQuestionPaperMessage] = useState<any>();

  const verifyLiveExamShift = async () => {
    await LiveService.verifyShift(examId)
      .then((res) => {
        if (res.status === 200) {
          verifyLiveExamQuestionPaper();
        }
      })
      .catch((err) => {
        setShiftMessage(err);
        setShiftError(true);
      });
  };

  const verifyLiveExamQuestionPaper = async () => {
    await LiveService.verifyQuestionPaper(examId)
      .then((res) => {
        if (res.response.status === 404) {
             setQuestionPaperMessage(res?.response?.data);
             setQuestionPaperError(true);
        }
      })
      .catch((err) => {
          verifyLiveExamClockTiming();
       
      });
  };

  const verifyAsymmetricKeys = async () => {
    await LiveService.verifyAsymmetricKeys(examId)
      .then((res) => {
        if (res.response.status === 406) {
             setAsymmetricKeysMessage(res?.response?.data);
             setAsymmetricKeysError(true);
        }
      })
      .catch((err) => {
       verifyExmsteData()
      });
  };

  const verifyExmsteData = async () => {
    await LiveService.verifyExmsteData(examId)
      .then((res) => {
        if (res.response.status === 406) {
          setExmsteDataMessage(res?.response?.data);
          setExmsteDataError(true);
        }
      })
      .catch((err) => {
        verifyBioUsers()
      });
  };
  const verifyBioUsers = async () => {
    await LiveService.verifyBioUsers(examId)
      .then((res) => {
        if (res.response.status === 406) {
          setBioUsersMessage(res?.response?.data);
          setBioUsersError(true);
        }
      })
      .catch((err) => {});
  };
  const verifyQpBankHooks = async () => {
    await LiveService.verifyQpBankHooks(examId)
      .then((res) => {
        if (res.response.status === 406) {
          setQpBankHooksMessage(res?.response?.data);
          setQpBankHooksError(true);
        }
      })
      .catch((err) => {
        verifyZone()
      });
  };
  const verifySection = async () => {
    await LiveService.verifySection(examId)
      .then((res) => {
        if (res.response.status === 406) {
          setSectionMessage(res?.response?.data);
          setSectionError(true);
        }
      })
      .catch((err) => {});
  };
  const verifyZone = async () => {
    await LiveService.verifyZone(examId)
      .then((res) => {
        if (res.response.status === 406) {
          setZoneMessage(res?.response?.data);
          setZoneError(true);
        }
      })
      .catch((err) => {
        verifyAsymmetricKeys()
      });
  };
  const verifyTimeBuffer = async () => {
    await LiveService.verifyTimeBuffer(examId)
      .then((res) => {
        if (res.response.status === 406) {
          setTimeBufferMessage(res?.response?.data);
          setTimeBufferError(true);
        }
      })
      .catch((err) => {});
  };
  const verifyCandidatesForCenter = async () => {
    await LiveService.verifyCandidatesForCenter(examId)
      .then((res) => {
        if (res.response.status === 406) {
          setCandidatesForCenterMessage(res?.response?.data);
          setCandidatesForCenterError(true);
        }
      })
      .catch((err) => {});
  };
  const verifyLiveExamClockTiming = async () => {
    await LiveService.verifyClockTimings(examId)
      .then((res) => {
        if (res.response.status === 406) {
          setlockTimingMessage(res?.response?.data);
          setClockTimmingError(true);
        }
      })
      .catch((err) => {
        verifyQpBankHooks();
      });
  };

  useEffect(() => {
    verifyLiveExamShift();
  }, []);

  return (
    <>
      <div className="mt-10 mx-5">
        <Card>
          <ol className="relative border-l border-gray-200 dark:border-gray-700">
            <LiveExamTimeline
              testData={"Test 1"}
              title={"Shifts"}
              descriptions={shiftMessage}
              error={shiftError}
              success={true}
            />
            {/* {shiftError === false && */}
            <LiveExamTimeline
              testData={"Test 2"}
              title={"Question Paper"}
              descriptions={questionPaperMessage}
              error={questionPaperError}
              success={true}
            />
            {/* } */}
            {/* {questionPaperError === false && */}
            <LiveExamTimeline
              testData={"Test 3"}
              title={"Clocks"}
              descriptions={clockTimingMessage}
              error={clockTimingError}
              success={true}
            />

            <LiveExamTimeline
              testData={"Test 4"}
              title={"Hooks for Question Bank"}
              descriptions={clockTimingMessage}
              error={clockTimingError}
              success={true}
            />

            <LiveExamTimeline
              testData={"Test 5"}
              title={"Zone Status"}
              descriptions={clockTimingMessage}
              error={clockTimingError}
              success={true}
            />

            <LiveExamTimeline
              testData={"Test 6"}
              title={"Checking For Assym. Keys"}
              descriptions={clockTimingMessage}
              error={clockTimingError}
              success={true}
            />

            <LiveExamTimeline
              testData={"Test 7"}
              title={"Checking For STE Data"}
              descriptions={clockTimingMessage}
              error={clockTimingError}
              success={true}
            />
            <LiveExamTimeline
              testData={"Test 8"}
              title={"Checking For Bio Users for STE"}
              descriptions={clockTimingMessage}
              error={clockTimingError}
              success={true}
            />

            {/* } */}
          </ol>
        </Card>
      </div>
    </>
  );
};

export default TestLiveExam;
