import { HiBookOpen, HiMegaphone } from "react-icons/hi2";
import IRouter from "../interfaces/IRouter";
import Center from "../views/Exam/Centers/Center";
import TestLiveExam from "../views/Exam/TestLiveExams/TestLiveExam";

const testLiveExamsRouter: IRouter[] = [
    {
        path: "liveExam",
        element: <TestLiveExam />,
        navbarShow: true,
        name: "Live Exam",
        icon: HiMegaphone,
    },
];

export default testLiveExamsRouter