import ExamSidebar from "../../components/Sidebar/Exam.sidebar";
import MonitoringSidebar from "../../components/Sidebar/Monitoring.sidebar";

interface IMonitoringIndex {
    render: any
}

export default function MonitoringIndex({ render }: IMonitoringIndex) {
    return (
        <>
            <MonitoringSidebar render={render} />
        </>
    )
}